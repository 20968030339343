import { DefaultValues } from 'helpers/convertHelper'

import { document } from './document'

export const main = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		title: model.string(['title']),
		context: {
			contextId: model.string(['context', 'contextId']),
			contextType: model.string(['context', 'contextType']),
		},
		documents: model.array(['documents']).map(document),
	}
}
