import { createAsyncThunk } from '@reduxjs/toolkit'
import api from 'api'
import uniq from 'ramda/src/uniq'
import { actions as listActions } from 'reducers/application/list'
import { GetParameter } from 'types/redux'

import { notice } from 'components/common/Notice'

export const single = {
	get: createAsyncThunk(
		'application/single',
		async (id: GetParameter<typeof api.application.get>) => {
			const { data } = await api.application.get(id)

			return data
		}
	),

	requestAction: createAsyncThunk(
		'application/single/requestAction',
		async (
			{ id, payload }: GetParameter<typeof api.application.requestAction>,
			{ rejectWithValue, fulfillWithValue, dispatch }
		) => {
			try {
				const { status } = await api.application.requestAction({ id, payload })

				notice({
					message:
						payload.action === 'APPROVE'
							? 'Заявка успешно согласована'
							: 'В согласовании заявки отказано',
					type: 'success',
				})

				if (payload.action === 'REFUSE') {
					dispatch(listActions.removeOne(id))
				}

				return fulfillWithValue(status)
			} catch (error: any) {
				const data = error?.response?.data

				if (data?.errorCode === 'VALIDATION-1') {
					Object.entries<string[]>(data.errors).forEach(([title, errors]) =>
						notice({
							title: 'Не хватает данных',
							message: `${title}:\n ${uniq(errors).join(', ')}`,
							props: { autoClose: false },
						})
					)
				}

				return rejectWithValue(error)
			}
		}
	),
}
