import { Maybe } from 'types/utils'

import { DefaultValues } from 'helpers/convertHelper'

const MAP_RUS = {
	LESS_5: 'до 5',
	LESS_20T: 'до 20 тыс',
	LESS_100T: 'до 100 тыс',
	UNDER_20: 'более 20',
	UNDER_50: 'более 50',
	UNDER_500T: 'от 500 тыс',
	UNDER_3M: 'более 3 млн',
	UNDER_5_LESS_20: 'от 5 до 20',
	UNDER_20_LESS_50: 'от 20 до 50',
	UNDER_100T_LESS_500T: 'от 100 тыс до 500 тыс',
	UNDER_500T_LESS_3M: 'от 500 тыс до 3 млн',
	UNDER_20T_LESS_50T: 'от 20 до 50 тыс',
	UNDER_50T_LESS_100T: 'от 50 тыс до 100 тыс',
} as const

const MAP = Object.entries(MAP_RUS).reduce((acc, [key]) => {
	const typedKey: keyof typeof MAP_RUS = key as keyof typeof MAP_RUS
	acc[typedKey] = typedKey
	return acc
}, {} as Record<keyof typeof MAP_RUS, keyof typeof MAP_RUS>)

type SourceKeys = 'OKB' | 'NBCH'
type NormalizedCustomerData = Record<SourceKeys, Maybe<Record<string, any>>>
type NormalizedRequestDto = Record<SourceKeys, Maybe<Record<string, any>>>

const normalizeBySource = <T extends { source: SourceKeys }>(data: T[]) =>
	data?.reduce(
		(acc, curr) => {
			if (curr.source) acc[curr.source] = curr
			return acc
		},
		{ NBCH: null, OKB: null } as Record<SourceKeys, Maybe<T>>
	)

const participant = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		type: model.string(['type']),
	}
}

const creditHistoryScheme = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	const normalizedCustomerData: NormalizedCustomerData = normalizeBySource(
		model.array(['customerData'])
	)
	const normalizedRequest: NormalizedRequestDto = normalizeBySource(model.array(['requests']))

	return {
		totalCreditLimit: model.null<number>(['totalCreditLimit']),
		totalCurrentBalance: model.null<number>(['totalCurrentBalance']),
		totalPastDueAmount: model.null<number>(['totalPastDueAmount']),
		totalOutstandingAmount: model.null<number>(['totalOutstandingAmount']),
		totalInstalmentAmount: model.null<number>(['totalInstalmentAmount']),
		requestsFor30DaysAggregation: model.const(data?.requestsFor30DaysAggregation, MAP),
		totalCreditLimitAggregation: model.const(data?.totalCreditLimitAggregation, MAP),
		totalCurrentBalanceAggregation: model.const(data?.totalCurrentBalanceAggregation, MAP),
		totalOutstandingAmountAggregation: model.const(data?.totalOutstandingAmountAggregation, MAP),
		totalInstalmentAmountAggregation: model.const(data?.totalInstalmentAmountAggregation, MAP),
		totalPastDueAmountAggregation: model.const(data?.totalPastDueAmountAggregation, MAP),
		activeCreditsOverdueAggregation: model.const(data?.activeCreditsOverdueAggregation, MAP),
		closedCreditsOverdueAggregation: model.const(data?.closedCreditsOverdueAggregation, MAP),
		credits: model
			.array<Record<string, any>[]>(['credits'])
			.sort((a, b) => new Date(b.openedAt).valueOf() - new Date(a.openedAt).valueOf()),
		customerData: normalizedCustomerData,
		requests: normalizedRequest,
		fico: data?.fico as Record<string, any>,
		activeCreditsOverdue: model.array<Array<Record<string, any>>>(['activeCreditsOverdue']),
		closedCreditsOverdue: model.array<Array<Record<string, any>>>(['closedCreditsOverdue']),
		totalCreditsOverdue: model.array<Array<Record<string, any>>>(['totalCreditsOverdue']),
		warnings: model.array<string[]>(['warnings']),
	}
}

export const creditHistory = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		createdAt: model.string(['createdAt']),
		participant: participant(data?.participant),
		creditHistory: creditHistoryScheme(data?.creditHistory),
	}
}
