import { briefDto } from './briefDto'
import { calculation } from './calculation'
import { facility } from './facility'
import { main } from './main'
import { moneyMovement } from './moneyMovement'
import { participant } from './participant'
import { status } from './status'
import { upcomingPayment } from './upcomingPayment'

export const loan = {
	briefDto,
	main,
	moneyMovement,
	upcomingPayment,
	facility,
	calculation,
	status,
	participant,
}

export namespace LoanDto {
	export type BriefDto = ReturnType<typeof briefDto>
	export type MoneyMovement = ReturnType<typeof moneyMovement>
	export type UpcomingPayment = ReturnType<typeof upcomingPayment>
	export type Calculation = ReturnType<typeof calculation>
	export type Status = ReturnType<typeof status>
	export type Participant = ReturnType<typeof participant>
	export type Facility = ReturnType<typeof facility>
	export type Main = ReturnType<typeof main>
}
