import { PARTICIPANT_ROLES_RUS } from 'const/participant'

import { DefaultValues } from 'helpers/convertHelper'
import { utils } from 'helpers/utils'

export const participant = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	const [surname, name, patronymic] = model.string(['title']).split(' ')
	const short = utils.join([surname && `${surname.charAt(0)}.`, name, patronymic], ' ')
	const title = model.string(['title'])

	return {
		id: model.string(['id']),
		title,
		short: data.taxId ? title : short,
		taxId: model.string(['taxId']),
		phone: model.string(['phone']),
		email: model.string(['email']),
		type: model.const(data.type, {
			PERSON: 'PERSON',
			ENTREPRENEUR: 'ENTREPRENEUR',
			ORGANIZATION: 'ORGANIZATION',
		} as const),
		typeRus: model.const(data.type, {
			PERSON: 'ФЛ',
			ENTREPRENEUR: 'ИП',
			ORGANIZATION: 'ЮЛ',
		} as const),
		roles: model.array<Array<'BORROWER' | 'OWNER' | 'WARRANTOR' | 'INVESTOR'>>(['roles']),
		rolesRus: model
			.array(['roles'])
			.map((role: keyof typeof PARTICIPANT_ROLES_RUS) => PARTICIPANT_ROLES_RUS[role])
			.filter(Boolean),
	}
}
