import { DefaultValues } from 'helpers/convertHelper'

import { payment } from './payment'

export const calculation = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		loanDurationMonths: model.null<number>(['loanDurationMonths']),
		paymentType: model.string(['paymentType']),
		amount: model.null<number>(['amount']),
		period: model.null<number>(['period']),
		typeRus: model.const(data.paymentType, {
			ANNUITY: 'Аннуитетный',
			PERCENT: 'Процентный',
			MIXED: 'Смешанный',
		} as const),
		payments: model
			.array(['payments'])
			.map(payment)
			.sort((a, b) => {
				if (a.to && a.from && b.to && b.from) {
					return a.to - a.from < b.to - b.from ? 1 : -1
				}
				return -1
			}),
	}
}
