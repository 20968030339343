import { memo, useCallback, useState } from 'react'

import Logo from './logo.svg'

export type ImageProps = React.DetailedHTMLProps<
	React.ImgHTMLAttributes<HTMLImageElement>,
	HTMLImageElement
>

export const Image = memo(({ src, className, ...props }: ImageProps) => {
	const [error, setError] = useState(false)

	const handleError = useCallback(() => {
		setError(true)
	}, [])

	return <img src={error ? Logo : src} className={className} onError={handleError} {...props} />
})
