import { memo, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ym, { YMInitializer } from 'react-yandex-metrika'
import { useKeycloak } from '@react-keycloak/web'

const COUNTER_ID = 90526126

export const MetrikaInitializer = memo((): JSX.Element | null => {
	const accounts =
		window.__env__.ENVIRONMENT === 'production' || window.location.search.includes('_ym_debug=1')
			? [COUNTER_ID]
			: []
	return <YMInitializer accounts={accounts} />
})

export const useMetrikaLogger = () => {
	const { pathname, search } = useLocation()

	useEffect(() => {
		const options = { pathname, search }
		ym('hit', `${pathname}${search}`, options)
	}, [pathname, search])

	const {
		keycloak: { tokenParsed },
	} = useKeycloak()
	const tokenUserId = tokenParsed?.sub || null

	useEffect(() => {
		if (!tokenUserId) {
			return
		}
		ym('setUserID', `${tokenUserId}`)
	}, [tokenUserId])
}

// export const metrikaReachGoal = (goal: string) => {
// 	ym('reachGoal', goal)
// }
