import { ComponentType, PropsWithChildren } from 'react'

import styles from './FadeIn.module.scss'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface WithFadeInProps {}

export function WithFadeIn<T extends WithFadeInProps = WithFadeInProps>(
	WrappedComponent: ComponentType<T>
) {
	return (props: T) => {
		return (
			<div className={styles.fadeIn}>
				<WrappedComponent {...(props as T)} />
			</div>
		)
	}
}

export const FadeInComponent = ({ children }: PropsWithChildren<unknown>) => {
	return <div className={styles.fadeIn}>{children}</div>
}
