import { DefaultValues } from 'helpers/convertHelper'

export const principalActivity = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		code: model.string(['code']),
		name: model.string(['name']),
	}
}
