import { DefaultValues } from 'helpers/convertHelper'

export const facility = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		type: {
			id: model.string(['type', 'id']),
			title: model.string(['type', 'title']),
		},
		address: {
			mergedAddress: model.string(['address', 'mergedAddress']),
		},
		area: model.null<number>(['area']),
		assessment: {
			price: model.null<number>(['assessment', 'price']),
		},
	}
}
