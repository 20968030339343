import { Options } from 'api/types'
import axios from 'axios'
import { application, ApplicationDto } from 'converters/application'
import { creditPolicy, CreditPolicyDto } from 'converters/creditPolicy'
export namespace ApiApplication {
	export type Search = Array<ApplicationDto.BriefDto>
	export type Get = ApplicationDto.Main
	export type RequestAction = void
	export type GetCreditPolicy = CreditPolicyDto.Main[]
}

const baseUrl = '/v1/applications'

const actions = {
	search: ({ page, size = 10 }: Options) =>
		axios
			.get<ApiApplication.Search>(baseUrl, { params: { page, size } })
			.then((response) =>
				Object.assign({}, response, { data: response.data.map(application.briefDto) })
			),

	get: ({ id }: { id: string }) =>
		axios
			.get<ApiApplication.Get>(`${baseUrl}/${id}`)
			.then((response) => Object.assign({}, response, { data: application.main(response.data) })),

	requestAction: ({
		id,
		payload,
	}: {
		id: string
		payload: { action: 'APPROVE' | 'REFUSE'; policyId: string }
	}) => axios.post<ApiApplication.RequestAction>(`${baseUrl}/${id}/action`, payload),

	getCreditPolicy: ({ id }: { id: string }) =>
		axios
			.get<ApiApplication.GetCreditPolicy>(`${baseUrl}/${id}/credit-policy-calculations`)
			.then((response) =>
				Object.assign({}, response, { data: response.data.map(creditPolicy.main) })
			),
}

export default actions
