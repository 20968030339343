import { DefaultValues } from 'helpers/convertHelper'

import { form } from './form'
import { indicators } from './indicators'
import { owner } from './owner'
import { ownershipDocument } from './ownershipDocument'
import { previousOwner } from './previousOwner'

export const main = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		cadastralId: model.string(['cadastralId']),
		address: model.string(['address']),
		status: {
			id: model.string(['status', 'id']),
			title: model.string(['status', 'title']),
		},
		facilityType: {
			id: model.string(['facilityType', 'id']),
			title: model.string(['facilityType', 'title']),
		},
		renovation: {
			id: model.string(['renovation', 'id']),
			title: model.string(['renovation', 'title']),
		},
		assessment: {
			averageSqmPrice: model.null<number>(['assessment', 'averageSqmPrice']),
			averagePrice: model.null<number>(['assessment', 'averagePrice']),
		},

		owners: model.array(['owners']).map(owner),
		previousOwners: model.array(['previousOwners']).map(previousOwner),
		ownershipDocuments: model.array(['ownershipDocuments']).map(ownershipDocument),

		indicators: indicators(data.indicators),
		form: form(data.form),
	}
}
