import { combineReducers } from '@reduxjs/toolkit'

import list from './list'
import selection from './selection'
import single from './single'

export default combineReducers({
	list,
	single,
	selection,
})
