import { isValidElement } from 'react'
import { MEDIA_BREAKPOINTS } from 'const/mediaBreakpoints'
import isNil from 'ramda/src/isNil'
import { Maybe, RateDetail } from 'types/utils'

import { MediaBreakpoints } from '@creditclubteam/kit/styled'

import { insurance } from '../converters/loan/insurance'
import { payment as Payment } from '../converters/loan/payments'

const mappedBoolValues = new Map().set(true, 'Да').set(false, 'Нет').set(null, 'Нет данных')

export const utils = {
	boolToText: (value: boolean | null) => mappedBoolValues.get(value),

	getTitleForField:
		(answers: { positive: string; negative: string; empty?: string }) =>
		(value: Maybe<boolean>) => {
			if (value === true) {
				return answers.positive
			}
			if (value === false) {
				return answers.negative
			}
			return answers.empty ?? 'Нет данных'
		},

	defaultOr: <T, D = string>(value: T, defaultValue: D = '—' as never): NonNullable<T> | D => {
		if (
			(typeof value === 'string' && value !== '') ||
			(typeof value === 'object' &&
				value !== null &&
				!Array.isArray(value) &&
				isValidElement(value)) ||
			Number.isFinite(value) ||
			(Array.isArray(value) && value.length)
		)
			return value as NonNullable<T>

		return defaultValue
	},

	formatNum: <
		T extends Maybe<number> | undefined,
		T2 extends { defaultValue?: T2['defaultValue'] } & Intl.NumberFormatOptions = {
			defaultValue: string
		}
	>(
		value: T,
		options?: T2
	): T extends number ? string : T2['defaultValue'] => {
		if (typeof value !== 'number') {
			return (options?.defaultValue === undefined ? '' : options.defaultValue) as ReturnType<
				typeof utils.formatNum
			>
		}

		return Intl.NumberFormat('ru-RU', Object.assign({ minimumFractionDigits: 0 }, options)).format(
			value
		)
	},

	random: (min: number, max: number, fractionDigits?: number) => {
		if (fractionDigits) {
			min = Math.floor(min)
			return +(Math.random() * (max - min) + min).toFixed(fractionDigits)
		}
		min = Math.ceil(min)
		max = Math.floor(max)
		return Math.floor(Math.random() * (max - min + 1)) + min // Максимум и минимум включаются
	},

	getMedia: (width: number): MediaBreakpoints => {
		if (width > MEDIA_BREAKPOINTS.tablet) {
			return 'desktop'
		} else if (width > MEDIA_BREAKPOINTS.mobile && width < MEDIA_BREAKPOINTS.tablet) {
			return 'tablet'
		}
		return 'mobile'
	},

	join: function <T>(data: T[], separator = ' ') {
		return data.filter(Boolean).join(separator)
	},
	getFullName: <T extends Record<string, any>>(data: T, cutFull = false) => {
		if (!data) return null

		const { name, surname, patronymic } = data

		let results = ''

		if (!surname && !patronymic) {
			results = name || ''
		} else {
			const willName: Record<string, string> = {
				surname,
				name,
				patronymic,
			}

			Object.keys(willName).forEach((key, i) => {
				if (willName[key]) {
					results += `${i !== 0 && cutFull ? willName[key][0] + '.' : willName[key]} `
				}
			})
		}

		return results.trim()
	},

	parsePhone: (unparsedPhone: string) => {
		if (!unparsedPhone || !/^[0-9/+-\s]*$/.test(unparsedPhone)) return null

		let phone, phone1, phone2, phone3, phone4
		unparsedPhone = unparsedPhone.toString()

		if (!isNil(unparsedPhone)) {
			if (unparsedPhone.length === 7) {
				phone1 = unparsedPhone.slice(0, 3)
				phone2 = unparsedPhone.slice(3, 5)
				phone3 = unparsedPhone.slice(5, 7)
				phone = `+7 ${phone1}-${phone2}-${phone3}`
			} else if (unparsedPhone.length === 10) {
				phone1 = unparsedPhone.slice(0, 3)
				phone2 = unparsedPhone.slice(3, 6)
				phone3 = unparsedPhone.slice(6, 8)
				phone4 = unparsedPhone.slice(8, 10)
				phone = `+7 ${phone1} ${phone2}-${phone3}-${phone4}`
			} else {
				phone = `+7 ${unparsedPhone}`
			}
		}

		return phone
	},

	unparsePhone: (phone: string) => {
		if (!phone || typeof phone !== 'string') return ''

		return phone.replace(/^(\D*?[78](\D*|\s)?){1}|\D/g, '')
	},

	formatAddress: (address: string) => {
		if (!address || typeof address !== 'string') return ''

		return address.split(',').slice(0, -1).join(',')
	},

	formatRateDetails: (payments: ReturnType<typeof Payment>[]) => {
		const res = payments.map((payment) => {
			const insertInterestRateDetail = payment.paymentInterestRateDetails.find(
				(interestRateDetail) => interestRateDetail.type === 'INTEREST'
			)
			return {
				interestRate: {
					value: insertInterestRateDetail?.interestRate.value,
					type: `% ${
						insertInterestRateDetail?.interestRate.unit === 'ANNUAL' ? 'годовых' : 'в месяц'
					}`,
				},
				from: payment.from,
				to: payment.to,
			}
		})
		return res
	},

	isAllSameValueRateDetails: (formatrateDetails: RateDetail[]) => {
		const oneFormatRateDetailValue = formatrateDetails[0].interestRate.value

		for (let i = 0; i < formatrateDetails.length; i++) {
			if (formatrateDetails[i].interestRate.value !== oneFormatRateDetailValue) {
				return false
			}
		}

		return true
	},
	getInsurance: (insuranceData: ReturnType<typeof insurance>[]) =>
		insuranceData.map((insurance) => {
			const products = insurance.products.map((item) => item.name).join(', ')
			return `${insurance.organization.name}: ${products}`
		}),
}
