import { DefaultValues } from 'helpers/convertHelper'

export const spouse = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		fullName: model.string(['fullName']),
	}
}
