import { DefaultValues } from 'helpers/convertHelper'

import { currentInvestor } from './currentInvestor'
import { loan } from './loan'
import { status } from './status'

export const main = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		number: model.string(['number']),
		createdAt: model.string(['createdAt']),
		totalMainDebtAmount: model.null<number>(['totalMainDebtAmount']),
		status: status(data.status),
		loan: loan(data.loan),
		currentInvestor: currentInvestor(data.currentInvestor),
		loans: model.array(['loans']).map(loan),
	}
}
