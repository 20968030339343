import { createAsyncThunk } from '@reduxjs/toolkit'
import api from 'api'
import { SignatureRequestDto } from 'reducers/sign/types'

export const fetchSignatures = createAsyncThunk('sign/fetchSignatures', async () => {
	const { content } = await api.sign.getSignatures({
		actual: true,
	})

	return content
})

export const signDocuments = createAsyncThunk(
	'sign/signDocuments',
	async (payload: SignatureRequestDto) => {
		await api.sign.sign(payload)

		return payload
	}
)
