import { DefaultValues } from 'helpers/convertHelper'

export const role = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string<
			'BORROWER' | 'WARRANTOR' | 'OWNER' | 'PREVIOUS_OWNER' | 'SPOUSE' | 'CONTROLLED_LEGAL_ENTITY'
		>(['id']),
		title: model.string(['title']),
	}
}
