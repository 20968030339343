import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { creditPolicy } from 'actions/application/creditPolicy'
import { CreditPolicyDto } from 'converters/creditPolicy'
import concat from 'ramda/src/concat'
import path from 'ramda/src/path'
import uniqBy from 'ramda/src/uniqBy'
import { commonActions } from 'reducers/common'
import { EntityStatus } from 'types/redux'

import {
	normalizeByProduct,
	NormalizedCreditPolicyCalculationData,
} from './normalizedSchemas/creditPolicy'

const creditPolicyAdapter = createEntityAdapter<CreditPolicyDto.Main>({
	selectId: ({ creditPolicy }) => creditPolicy.id,
})

const initialState = creditPolicyAdapter.getInitialState({
	status: 'pending' as EntityStatus,
	selection: {
		program: '',
		creditPolicyId: '',
	},
	productsIds: [] as string[],
	normalizedSchema: {} as NormalizedCreditPolicyCalculationData['entities'],
})

const application = createSlice({
	name: 'application/creditPolicy',
	initialState,
	reducers: {
		setSelection: (
			state,
			{ payload }: PayloadAction<Partial<typeof initialState['selection']>>
		) => {
			state.selection = { ...state.selection, ...payload }
		},

		reset: commonActions.reset(initialState),
	},
	extraReducers: (builder) => {
		builder
			.addCase(creditPolicy.get.pending, (state) => {
				if (state.status !== 'pending') state.status = 'pending'
			})
			.addCase(creditPolicy.get.fulfilled, (state, { payload }) => {
				const list: CreditPolicyDto.Main[] = state.ids.length
					? uniqBy(
							path(['creditPolicy', 'id']),
							concat(
								payload,
								state.ids.map((id) => state.entities[id]!)
							)
					  )
					: payload

				const { entities, processedData, productsIds } = normalizeByProduct(list)
				state.productsIds = productsIds
				state.normalizedSchema = entities
				creditPolicyAdapter.setMany(state, processedData)
				state.status = 'fulfilled'
			})
			.addCase(creditPolicy.get.rejected, () => ({
				...initialState,
				status: 'rejected',
			}))
	},
})

export const { actions } = application
export default application.reducer
