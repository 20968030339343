import { DefaultValues } from 'helpers/convertHelper'

import { participant } from './participant'

export const briefDto = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		number: model.string(['number']),
		product: model.string(['product']),
		loanStartDate: model.string(['loanStartDate']),
		createdAt: model.string(['createdAt']),
		city: model.string(['city']),
		borrower: data.borrower ? participant(data.borrower) : null,
		mainDebtAmount: model.null<number>(['mainDebtAmount']),
		loanDuration: model.null<number>(['loanDuration']),
		annualInterestRate: model.null<number>(['annualInterestRate']),
		ltv: model.null<number>(['ltv']),
		scheduledClosingDate: model.string(['scheduledClosingDate']),
		mortgage: model.null<boolean>(['mortgage']),
		scoreCC: model.null<number>(['scoreCC']),
	}
}
