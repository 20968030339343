import * as React from 'react'
import clsx from 'clsx'
import { Indents } from 'types/media'

import svgCardBG2 from './svg/background/card-greed-2.svg'
import svgCardBG3 from './svg/background/card-greed-3.svg'
import svgCardBG4 from './svg/background/card-greed-4.svg'
import svgCardBG5 from './svg/background/card-greed-5.svg'
import svgCardBG6 from './svg/background/card-greed-6.svg'
import svgCardBG7 from './svg/background/card-greed-7.svg'
import svgCardBG8 from './svg/background/card-greed-8.svg'
import svgCardBG9 from './svg/background/card-greed-9.svg'
import svgBalance from './svg/balance.svg'
import svgCardPattern from './svg/card-pattern.svg'
import svgChecklist from './svg/checklist.svg'
import svgClient from './svg/client.svg'
import svgClose from './svg/close.svg'
import svgDocument from './svg/document.svg'
import svgFactory from './svg/factory.svg'
import svgLaptop from './svg/laptop.svg'
import svgLocation from './svg/location.svg'
import svgMenuBurger from './svg/menuBurger.svg'
import svgOnlineRegistration from './svg/online-registration.svg'
import svgPlusCircle from './svg/plus-circle.svg'
import svgPortfel from './svg/portfel.svg'
import svgPresentation from './svg/presentation.svg'
import svgSettings from './svg/settings.svg'
import svgSign from './svg/sign.svg'

import styles from './Icon.module.scss'

export type IconName =
	| 'presentation'
	| 'cardPattern'
	| 'plusCircle'
	| 'settings'
	| 'factory'
	| 'location'
	| 'onlineRegistration'
	| 'checklist'
	| 'document'
	| 'portfel'
	| 'sign'
	| 'laptop'
	| 'balance'
	| 'close'
	| 'client'
	| 'cardBG2'
	| 'cardBG3'
	| 'cardBG4'
	| 'cardBG5'
	| 'cardBG6'
	| 'cardBG7'
	| 'cardBG8'
	| 'cardBG9'
	| 'menuBurger'

// В react-app-env.d.ts у нас импорт svg идёт как строка, потому тут такой код
const Map: Record<IconName, string> = {
	presentation: svgPresentation,
	cardPattern: svgCardPattern,
	plusCircle: svgPlusCircle,
	settings: svgSettings,
	factory: svgFactory,
	location: svgLocation,
	onlineRegistration: svgOnlineRegistration,
	checklist: svgChecklist,
	document: svgDocument,
	portfel: svgPortfel,
	laptop: svgLaptop,
	sign: svgSign,
	balance: svgBalance,
	close: svgClose,
	client: svgClient,
	cardBG2: svgCardBG2,
	cardBG3: svgCardBG3,
	cardBG4: svgCardBG4,
	cardBG5: svgCardBG5,
	cardBG6: svgCardBG6,
	cardBG7: svgCardBG7,
	cardBG8: svgCardBG8,
	cardBG9: svgCardBG9,
	menuBurger: svgMenuBurger,
}

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
	name: IconName
	className?: string
	indent?: Indents
}

const Icon = ({ name, indent, ...svgProps }: Props) => {
	const indentClassName = indent ? styles[indent] : null
	return (
		<img src={Map[name]} className={clsx(indentClassName, svgProps?.className)} {...svgProps} />
	)
}

export { Icon }
