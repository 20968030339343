import { createElement } from 'react'
import clxs from 'clsx'
import { Indents, Weights } from 'types/media'

import styles from './Text.module.scss'

export interface TextProps<T = any> {
	type?:
		| 'h1'
		| 'h2'
		| 'h3'
		| 'h4'
		| 'featured'
		| 'button'
		| 'body-large'
		| 'body'
		| 'small'
		| 'extra-small'
	theme?: 'link' | 'secondary' | 'error' | 'warn' | 'success'
	ellipsis?: boolean
	indent?: Indents
	weight?: Weights
	tag?: React.ElementType
	className?: string
	onClick?: React.AllHTMLAttributes<T>['onClick']
	tagProps?: React.AllHTMLAttributes<T>
}

const hElements = Array.from({ length: 4 }).map((_, i) => `h${i + 1}`)

const Text = (props: React.PropsWithChildren<TextProps>) => {
	const {
		type = 'body',
		weight,
		tagProps,
		onClick,
		children,
		className,
		tag = 'p',
		indent,
		ellipsis = false,
		theme,
	} = props

	const themeClassName = theme ? styles[`--${theme}`] : '--default'
	const weightClassName = weight ? styles[`--weight-${weight}`] : null
	const indentClassName = indent ? styles[indent] : null

	if (hElements.includes(type)) {
		return createElement(
			type,
			{
				...tagProps,
				onClick,
				className: clxs(
					styles.element,
					{ [styles.ellipsis]: ellipsis },
					themeClassName,
					weightClassName,
					indentClassName,
					className
				),
			},
			children
		)
	}

	return createElement(
		tag,
		{
			...tagProps,
			onClick,
			className: clxs(
				styles.element,
				styles[type],
				{
					[styles.pointer]: !!onClick,
					[styles.ellipsis]: ellipsis,
				},
				themeClassName,
				weightClassName,
				indentClassName,
				className
			),
		},
		children
	)
}

export { Text }
