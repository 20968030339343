import { DefaultValues } from 'helpers/convertHelper'

export const creditPolicy = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		program: model.string(['program']),
		product: model.string(['product']),
	}
}
