import { createAsyncThunk } from '@reduxjs/toolkit'
import api from 'api'
import { actions } from 'reducers/entrepreneur/single'
import { GetParameter } from 'types/redux'

export const single = {
	get: createAsyncThunk(
		'entrepreneur/single/get',
		async (params: GetParameter<typeof api.entrepreneur.get>) => {
			const { data } = await api.entrepreneur.get(params)

			return data
		}
	),

	documents: createAsyncThunk(
		'entrepreneur/single/documents',
		async (params: GetParameter<typeof api.entrepreneur.getDocuments>) => {
		let page = 0;
		const { data } = await api.entrepreneur.getDocuments({ ...params, page})
		
		if (data.totalPages < 2) {
			return data.content
		}

		const documents = data.content
		
		for (let i = 0; i < data.totalPages - 1; i ++) {
			page += 1;
			const { data } = await api.entrepreneur.getDocuments({...params, page})
			documents.push(...data.content)
		}
		
		return documents
			
		}
	),

	...actions,
}
