import { createAsyncThunk } from '@reduxjs/toolkit'
import api from 'api'
import { ApiApplication } from 'api/application'
import { actions } from 'reducers/pagination'
import { GetParameter, RootState } from 'types/redux'

export const list = {
	get: createAsyncThunk<
		ApiApplication.Search,
		GetParameter<typeof api.application.search> | void,
		{ state: RootState }
	>('application/list/get', async (parameters = {}, { getState, dispatch }) => {
		const page = parameters?.page ?? getState().pagination.page

		const { data } = await api.application.search({
			...parameters,
			page,
		})

		dispatch(actions.set({ page }))

		return data
	}),

	...actions,
}
