import { DefaultValues } from 'helpers/convertHelper'

export const main = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		type: model.const(data.type, {
			PRELIMINARY_SCHEDULE: 'PRELIMINARY_SCHEDULE', // Запрос предварительного графика
		} as const),
		createdAt: model.string(['createdAt']),
		title: model.string(['title']), // Заголовок запроса. Например "Предоставьте график по зявке на 12.11.2022"
		context: model.string(['context']), // Субъект запроса. Например "Заявка №32800 на 950000 ₽, заёмщик Иванова Т.Н."
		applicationId: model.string(['parameters', 'applicationId']),
	}
}
