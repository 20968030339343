import { createAsyncThunk } from '@reduxjs/toolkit'
import api from 'api'
import { GetParameter } from 'types/redux'

import { notice } from 'components/common/Notice'

export const single = {
	get: createAsyncThunk(
		'client/single/get',
		async (params: GetParameter<typeof api.client.get>) => {
			const { data } = await api.client.get(params)

			return data
		}
	),

	create: createAsyncThunk(
		'client/single/create',
		async (params: GetParameter<typeof api.client.create>) => {
			const { data } = await api.client.create(params)

			window.location.href = '/client/' + data.id

			return data
		}
	),

	update: createAsyncThunk(
		'client/single/update',
		async (params: GetParameter<typeof api.client.update>, { dispatch, rejectWithValue }) => {
			try {
				await api.client.update(params)

				dispatch(single.get({ id: params.id! }))
			} catch (error: any) {
				notice({
					title: 'Ошибка обновления клиента',
					message: error?.response?.data?.message,
				})

				return rejectWithValue(error)
			}
		}
	),
}
