import { DefaultValues } from 'helpers/convertHelper'

import { bankForm } from './bankForm'
import { form } from './form'

const participant = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		title: model.string(['title']),
		taxId: model.string(['taxId']),
		phone: model.string(['phone']),
		email: model.string(['email']),
		type: model.const(data.type, {
			ORGANIZATION: 'ORGANIZATION',
			PERSON: 'PERSON',
		} as const),
		typeRus: model.const(data.type, {
			ORGANIZATION: 'ЮЛ',
			PERSON: 'ФЛ',
		} as const),
		roles: model.array<string[]>(['roles']),
	}
}

export const organization = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		email: model.string(['email']),
		phone: model.string(['phone']),
		taxId: model.string(['taxId']),
		form: form(data.form),
		bankForm: bankForm(data.bankForm),
		sites: model.array<string[]>(['sites']),
		participants: model.array(['participants']).map(participant),
	}
}
