import { useCallback, useEffect, useState } from 'react'
import debounce from 'lodash/debounce'

import { MediaBreakpoints } from '@creditclubteam/kit/styled'
import { utils } from 'helpers/utils'

const useMedia = () => {
	const [media, setMedia] = useState<MediaBreakpoints>(utils.getMedia(window.innerWidth))

	const handleResize = useCallback(() => {
		debounce(() => {
			const newMedia = utils.getMedia(window.innerWidth)

			if (newMedia !== media) {
				setMedia(newMedia)
			}
		}, 250)
	}, [media])

	useEffect(() => {
		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [media, handleResize])

	return media
}

export { useMedia }
