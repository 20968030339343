import { DefaultValues } from 'helpers/convertHelper'

import { payment } from './payments'

export const calculation = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	const overallRate =
		data.loanRate && data.membershipFeeRate
			? Number(data.loanRate) + Number(data.membershipFeeRate) * 12
			: null

	return {
		amount: model.null<number>(['amount']),
		outstandingMainDebt: model.null<number>(['outstandingMainDebt']),
		period: model.null<number>(['period']),
		loanRate: model.null<number>(['loanRate']),
		membershipFeeRate: model.null<number>(['membershipFeeRate']),
		payments: model
			.array(['payments'])
			.map(payment)
			.sort((a, b) => {
				if (a.to && a.from && b.to && b.from) {
					return a.to - a.from < b.to - b.from ? 1 : -1
				}
				return -1
			}),
		overallRate,
		type: model.const(data.type, {
			ANNUITY: 'ANNUITY',
			PERCENT: 'PERCENT',
			MIXED: 'MIXED',
		} as const),
		typeRus: model.const(data.type, {
			ANNUITY: 'Аннуитетный',
			PERCENT: 'Процентный',
			MIXED: 'Смешанный',
		} as const),
	}
}
