import axios from 'axios'

export namespace ApiModules {
	export type RequestAction = void
}

const baseUrl = '/v1/modules'

export interface RequestActionPayload {
	name: string
	surname: string
	patronymic: string
	phone: string
	position: string
	module: string
}

const actions = {
	requestAction: (payload: RequestActionPayload) =>
		axios.post<ApiModules.RequestAction>(`${baseUrl}`, payload),
}

export default actions
