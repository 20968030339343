import { PropsWithChildren } from 'react'
import ReactModal from 'react-modal'
import cx from 'clsx'

import { Icon } from '../Icon/Icon'
import { Text } from '../Text'

import styles from './Modal.module.scss'

interface ModalProps {
	isOpen: boolean
	title?: string
	showCloseButton?: boolean
	subText?: React.ReactNode
	className?: string
	overlayClassName?: string
	headerClassName?: string
	closeTimeoutMS?: number
	shouldCloseOnOverlayClick?: boolean

	onClose: () => void
	onAfterClose?: () => void
}

const Modal = (props: PropsWithChildren<ModalProps>) => {
	const {
		isOpen,
		onClose,
		className,
		onAfterClose,
		overlayClassName,
		shouldCloseOnOverlayClick,
		children,
		title,
		showCloseButton,
		headerClassName,
		subText,
	} = props

	const modalProps: React.ComponentProps<typeof ReactModal> = {
		isOpen,
		ariaHideApp: false,
		onRequestClose: onClose,
		onAfterClose,
		shouldCloseOnOverlayClick,
		className: cx(styles.modal, className),
		overlayClassName: cx(styles.modalOverlay, overlayClassName),
		closeTimeoutMS: 500,
	}

	return (
		<ReactModal {...modalProps}>
			{showCloseButton && (
				<span className={styles.cross} onClick={onClose}>
					<Icon name='close' />
				</span>
			)}
			{(title || subText) && (
				<div className={cx(styles.header, headerClassName)}>
					{title && (
						<Text type='h2' className={styles.title}>
							{title}
						</Text>
					)}
					{subText}
				</div>
			)}
			{children}
		</ReactModal>
	)
}

Modal.defaultProps = {
	shouldCloseOnOverlayClick: true,
	closeTimeoutMS: 500,
	showCloseButton: true,
}

export { Modal }
