import { DefaultValues } from 'helpers/convertHelper'

const analytics = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		debtAmount: model.string(['debtAmount']),
		creditPaymentsDebtAmount: model.string(['creditPaymentsDebtAmount']),
		taxesAndDutiesDebtAmount: model.string(['taxesAndDutiesDebtAmount']),
		completedProceedingsCount: model.string(['completedProceedingsCount']),
	}
}

export const proceedingGroup = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		createdAt: model.string(['createdAt']),
		modifiedAt: model.string(['modifiedAt']),
		analytics: analytics(data.analytics),
	}
}
