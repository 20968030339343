import { lazy } from 'react'

import { lazyLoadHandler } from 'helpers/lazyLoadHandler'

const List = lazy(() => lazyLoadHandler(() => import('./List')))
const Single = lazy(() => lazyLoadHandler(() => import('./Single')))

export default {
	Single,
	List,
}
