import { createAsyncThunk } from '@reduxjs/toolkit'
import api from 'api'
import { ApiLoanSellApplications } from 'api/loanSellApplications'
import { selectMarketGetActiveSelection } from 'reducers/market/selectors'
import { actions as paginationActions } from 'reducers/pagination'
import { GetParameter, RootState } from 'types/redux'

import { notice } from 'components/common/Notice'

export const list = {
	get: createAsyncThunk<
		ApiLoanSellApplications.Search,
		GetParameter<typeof api.loanSellApplications.search> | undefined,
		{ state: RootState }
	>('loanSellApplications/list/get', async ({ page, size } = {}, { getState, dispatch }) => {
		const { data } = await api.loanSellApplications.search({
			page: page ?? getState().pagination.page,
			size,
		})

		if (Number.isInteger(page)) {
			dispatch(paginationActions.set({ page }))
		}

		return data
	}),

	applyApplication: createAsyncThunk<
		void,
		GetParameter<typeof api.loanSellApplications.create> | undefined,
		{ state: RootState }
	>(
		'loanSellApplications/list/applyApplication',
		async (payload, { getState, rejectWithValue }) => {
			try {
				await api.loanSellApplications.create(
					payload ?? { loans: selectMarketGetActiveSelection(getState()) }
				)

				notice({ message: 'Заявка на покупку кредитов успешно создана', type: 'success' })
			} catch (error: any) {
				const message = error?.response?.data?.message

				notice({ title: 'Ошибка', message, props: { autoClose: 10 * 1000 } })

				return rejectWithValue(error?.response?.data ?? {})
			}
		}
	),
}
