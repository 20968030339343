import { DefaultValues } from 'helpers/convertHelper'

export const certificate = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		activeFrom: model.string(['activeFrom']),
		activeTo: model.string(['activeTo']),
	}
}
