import { DefaultValues } from 'helpers/convertHelper'

import { encumbrance } from './encumbrance'

export const right = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		regNumber: model.string(['regNumber']),
		regDate: model.string(['regDate']),
		type: model.string(['type']),

		owners: model.array<string[]>(['owners']),

		encumbrance: model.array(['encumbrance']).map(encumbrance),
	}
}
