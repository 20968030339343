import { createSlice } from '@reduxjs/toolkit'

interface State {
	isOpen: boolean
}

const initialState: State = {
	isOpen: false,
}

const application = createSlice({
	name: 'modals/joinUser',
	initialState,
	reducers: {
		openModal: (state: State) => {
			state.isOpen = true
		},
		closeModal: (state: State) => {
			state.isOpen = false
		},
	},
})

export const { actions } = application
export default application.reducer
