import { DefaultValues } from 'helpers/convertHelper'

export const status = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		title: model.string(['title']),
	}
}
