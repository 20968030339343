import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { list } from 'actions/application/list'
import { ApplicationDto } from 'converters/application'
import { commonActions } from 'reducers/common'
import { EntityStatus } from 'types/redux'

export const applicationAdapter = createEntityAdapter<ApplicationDto.BriefDto>()

const initialState = applicationAdapter.getInitialState({
	status: 'pending' as EntityStatus,
})

const application = createSlice({
	name: 'application/list',
	initialState,
	reducers: {
		removeOne: applicationAdapter.removeOne,
		reset: commonActions.reset(initialState),
	},
	extraReducers: (builder) => {
		builder
			.addCase(list.get.pending, (state) => {
				if (state.status !== 'pending') state.status = 'pending'
			})
			.addCase(list.get.fulfilled, (state, { payload }) => {
				applicationAdapter.setAll(state, payload)
				state.status = 'fulfilled'
			})
			.addCase(list.get.rejected, () => ({
				...initialState,
				status: 'rejected',
			}))
	},
})

export const { actions } = application
export default application.reducer
