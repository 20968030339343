import { DefaultValues } from 'helpers/convertHelper'

import { bankForm } from './bankForm'
import { form } from './form'
import { person } from './person'

export const main = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		taxId: model.string(['taxId']),

		person: person(data.person),
		form: form(data.form),
		bankForm: bankForm(data.bankForm),
	}
}
