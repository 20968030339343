import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { list } from 'actions/client/list'
import { ClientDto } from 'converters/client'
import { commonActions } from 'reducers/common'
import { EntityStatus } from 'types/redux'

export const clientAdapter = createEntityAdapter<ClientDto.Main>()

const initialState = clientAdapter.getInitialState({
	status: 'pending' as EntityStatus,
})

const client = createSlice({
	name: 'client/list',
	initialState,
	reducers: {
		add: clientAdapter.upsertOne,
		reset: commonActions.reset(initialState),
	},
	extraReducers: (builder) => {
		builder
			.addCase(list.get.pending, (state) => {
				if (state.status !== 'pending') state.status = 'pending'
			})
			.addCase(list.get.fulfilled, (state, action) => {
				clientAdapter.setAll(state, action.payload)
				state.status = 'fulfilled'
			})
			.addCase(list.get.rejected, () => ({
				...initialState,
				status: 'rejected',
			}))
	},
})

export const { actions } = client
export default client.reducer
