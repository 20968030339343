import { creditHistory } from './creditHistory'
import { dependent } from './dependent'
import { incomes } from './incomes'
import { main } from './main'
import { proceedingGroup } from './proceedingGroup'

export const person = {
	main,
	creditHistory,
	proceedingGroup,
	dependent,
	incomes,
	document,
}

export namespace PersonDto {
	export type Main = ReturnType<typeof main>
	export type CreditHistory = ReturnType<typeof creditHistory>
	export type ProceedingGroup = ReturnType<typeof proceedingGroup>
	export type Dependent = ReturnType<typeof dependent>
	export type Incomes = ReturnType<typeof incomes>
}
