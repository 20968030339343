import { DefaultValues } from 'helpers/convertHelper'

export const incomes = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		period: model.string(['period']),
		amount: model.null<number>(['amount']),
		proofSource: model.const(data?.proofSource, {
			CLIENT: 'CLIENT',
			PFR: 'PFR',
			NDFL2: 'NDFL2',
			LEASE_CONTRACT: 'LEASE_CONTRACT',
		} as const),
		proofSourceRus: model.const(data?.proofSource, {
			CLIENT: 'Клиент',
			PFR: 'Справка ПФР',
			NDFL2: 'Справка 2-НДФЛ',
			LEASE_CONTRACT: 'Договор аренды',
		} as const),
	}
}
