import application from './application'
import { client } from './client'
import address from './dadata'
import { document } from './document'
import { entrepreneur } from './entrepreneur'
import { facility } from './facility'
import { lenderRequest } from './lenderRequest'
import { loan } from './loan'
import { loanSellApplications } from './loanSellApplications'
import { market } from './market'
import modules from './modules'
import { organization } from './organization'
import { person } from './person'
import sign from './sign'

const rootActions = {
	application,
	client,
	document,
	market,
	lenderRequest,
	address,
	sign,
	loanSellApplications,
	loan,
	modules,
	person,
	facility,
	entrepreneur,
	organization,
}

export default rootActions
