import { DefaultValues } from 'helpers/convertHelper'

export const owner = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		title: model.string(['title']),
		taxId: model.string(['taxId']),
		phone: model.string(['phone']),
		email: model.string(['email']),
		part: model.null<number>(['part']),
		type: model.const(data.type, {
			PERSON: 'PERSON',
			ORGANIZATION: 'ORGANIZATION',
		} as const),
		typeRus: model.const(data.type, {
			PERSON: 'ФЛ',
			ORGANIZATION: 'Организация',
		} as const),
	}
}
