import styles from './UploadProgressBar.module.scss'

export const UploadProgressBar = ({ progress }: { progress: number }) => {
	return (
		<div className={styles.bar}>
			<div>Загрузка файлов {progress}%</div>
			<div className={styles.progress} style={{ width: `${progress}%` }}>
				{' '}
			</div>
		</div>
	)
}
