import { Options, Pageable } from 'api/types'
import axios from 'axios'
import { document, DocumentDto } from 'converters/document'
import {
	loanSellApplications as loanSellApplicationsConverter,
	LoanSellApplicationsDto,
} from 'converters/loanSellApplications'
import assocPath from 'ramda/src/assocPath'

export namespace ApiLoanSellApplications {
	export type Get = LoanSellApplicationsDto.Main
	export type Search = Pageable<LoanSellApplicationsDto.Main>
	export type Documents = Pageable<DocumentDto.Main>
}

const baseUrl = '/v1/market/loan-sell-applications'

export const loanSellApplications = {
	get: ({ id }: { id: string }) =>
		axios
			.get<ApiLoanSellApplications.Get>(`${baseUrl}/${id}`)
			.then((response) =>
				Object.assign({}, response, { data: loanSellApplicationsConverter.main(response.data) })
			),

	search: ({ page, size = 10 }: Options) =>
		axios
			.get<ApiLoanSellApplications.Search>(baseUrl, {
				params: { page, size, sort: 'createdAt,desc' },
			})
			.then((response) =>
				assocPath(
					['data', 'content'],
					response.data.content.map(loanSellApplicationsConverter.main),
					response
				)
			),

	create: (payload: { loans: string[] }) => axios.post(baseUrl, payload),

	cancel: ({ id }: { id: string }) => axios.post(`${baseUrl}/${id}/cancel`),

	documents: ({ id }: { id: string }) =>
		axios
			.get<ApiLoanSellApplications.Documents>(`${baseUrl}/${id}/documents`, {
				params: { size: 100 },
			})
			.then((response) =>
				assocPath(['data', 'content'], response.data.content.map(document.main), response)
			),
}
