import { createSlice } from '@reduxjs/toolkit'
import { single } from 'actions/person/single'
import { PersonDto } from 'converters/person'
import { commonActions } from 'reducers/common'
import { EntityStatus } from 'types/redux'
import { Maybe } from 'types/utils'

interface State {
	data: Maybe<PersonDto.Main>
	incomes: Maybe<PersonDto.Incomes[]>
	status: EntityStatus
}

const initialState: State = {
	data: null,
	incomes: null,
	status: 'pending',
}

const person = createSlice({
	name: 'person/single',
	initialState,
	reducers: {
		setIncomes: (state, { payload }) => {
			state.incomes = payload
		},

		reset: commonActions.reset(initialState),
	},
	extraReducers: (builder) => {
		builder
			.addCase(single.get.pending, (state) => {
				if (state.status !== 'pending') state.status = 'pending'
			})
			.addCase(single.get.fulfilled, (state, action) => {
				state.data = action.payload
				state.status = 'fulfilled'
			})
			.addCase(single.get.rejected, () => ({
				...initialState,
				status: 'rejected',
			}))

		builder.addCase(single.incomes.fulfilled, (state, action) => {
			state.incomes = action.payload
		})
	},
})

export const { actions } = person
export default person.reducer
