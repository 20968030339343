import { DefaultValues } from 'helpers/convertHelper'

import { creditHistory } from './creditHistory'
import { person } from './person'
import { proceedingGroup } from './proceedingGroup'

export const main = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		person: person(data?.person),
		creditHistory: model.array(['creditHistory']).map(creditHistory),
		proceedingGroup: model
			.array(['proceedingGroup'])
			.map(proceedingGroup)
			.sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()),
	}
}
