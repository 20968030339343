import { DefaultValues } from 'helpers/convertHelper'

import { court } from './court'

const analytics = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		loanCourts: model.null<number>(['loanCourts']),
		amountLoanCourts: model.null<number>(['amountLoanCourts']),
		amountNonPropertyCourts: model.null<number>(['amountNonPropertyCourts']),
		amountLegalDebtCourts: model.null<number>(['amountLegalDebtCourts']),
		amountLegalDebtCourtsOver500: model.null<number>(['amountLegalDebtCourtsOver500']),
		negativeNonPropertyCourts: model.null<number>(['negativeNonPropertyCourts']),
		avoidLoanCourts: model.null<number>(['avoidLoanCourts']),
		bankruptcyCourtsAsDefendant: model.null<number>(['bankruptcyCourtsAsDefendant']),
		negativeBankruptcyCourtsAsThird: model.null<number>(['negativeBankruptcyCourtsAsThird']),
		negativeCriminalAdministrativeCourts: model.null<number>([
			'negativeCriminalAdministrativeCourts',
		]),
	}
}

export const courtGroup = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		createdAt: model.string(['createdAt']),
		modifiedAt: model.string(['modifiedAt']),

		courts: model.array(['courts']).map(court),
		analytics: analytics(data.analytics),
	}
}
