import { DefaultValues } from 'helpers/convertHelper'

export const moneyMovement = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		type: model.const(data.type, {
			LOAN_ISSUE: 'LOAN_ISSUE',
			LOAN_REPAYMENT: 'LOAN_REPAYMENT',
			REFILL: 'REFILL',
		} as const),
		typeRus: model.const(data.type, {
			LOAN_ISSUE: 'Выдача кредита',
			LOAN_REPAYMENT: 'Погашение по кредиту',
			REFILL: 'Поступление на счёт',
		} as const),
		date: model.string(['date']),
		details: model.array(['details']).map((item, i) => ({
			type: model.const(item.type, {
				MAIN_DEBT: 'MAIN_DEBT',
				PERCENT: 'PERCENT',
				MEMBERSHIP_FEE: 'MEMBERSHIP_FEE',
				PENALTIES: 'PENALTIES',
				UNKNOWN: 'UNKNOWN',
				OVERDUE_BALANCE: 'OVERDUE_BALANCE',
			} as const),
			typeRus: model.const(item.type, {
				MAIN_DEBT: 'Основной долг',
				PERCENT: 'Проценты',
				MEMBERSHIP_FEE: 'Членские взносы',
				PENALTIES: 'Пени',
				UNKNOWN: 'Прочее',
				OVERDUE_BALANCE: 'Просроченная задолженность',
			} as const),
			amount: model.null<number>(['details', i, 'amount']),
		})),
		amount: model.null<number>(['amount']),
	}
}
