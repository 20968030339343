import { useMemo } from 'react'
import clsx from 'clsx'
import { Indents } from 'types/media'

import styles from './Button.module.scss'

export interface ButtonProps
	extends React.DetailedHTMLProps<
		React.ButtonHTMLAttributes<HTMLButtonElement>,
		HTMLButtonElement
	> {
	theme?: 'blue' | 'green' | 'error' | 'grey' | 'min-blue' | 'min-red'
	size?: 'min'
	fullWidth?: boolean
	indent?: Indents
}

const Button = ({
	theme,
	fullWidth,
	indent,
	className,
	size,
	...attributes
}: React.PropsWithChildren<ButtonProps>) => {
	const isMinThemeSize = useMemo(
		() => theme?.split('-').includes('min') || size === 'min',
		[theme, size]
	)

	return (
		<button
			className={clsx(
				styles.wrap,
				isMinThemeSize ? styles.minSize : styles.defaultSize,
				indent && styles[indent],
				theme && styles[theme],
				{
					[styles.full]: fullWidth,
					[className!]: !!className,
				}
			)}
			onClick={attributes.disabled ? undefined : attributes.onClick}
			{...attributes}
		>
			{attributes.children}
		</button>
	)
}

Button.defaultProps = {
	type: 'submit',
	fullWidth: true,
}

export { Button }
