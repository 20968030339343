import { Options } from 'api/types'
import axios from 'axios'
import { client as converter, ClientDto } from 'converters/client'

export namespace ApiClient {
	export type Search = ClientDto.Main[]
	export type Get = ClientDto.Main
	export type Create = ClientDto.Main
	export type Update = void
}

export const client = {
	search: ({ page, size = 10, filter }: Options) =>
		axios
			.get<ApiClient.Search>(`/v1/clients`, { params: { page, size, filter } })
			.then((response) => Object.assign({}, response, { data: response.data.map(converter.main) })),

	get: ({ id }: { id: string }) =>
		axios
			.get<ApiClient.Get>(`/v1/clients/${id}`)
			.then((response) => Object.assign({}, response, { data: converter.main(response.data) })),

	create: (data: Record<string, any>) =>
		axios
			.post<ApiClient.Create>(`/v1/clients`, data)
			.then((response) => Object.assign({}, response, { data: converter.main(response.data) })),

	update: (data: Record<string, any>) =>
		axios.patch<ApiClient.Update>(`/v1/clients/${data.id}`, data, {
			headers: {
				'Content-Type': 'application/merge-patch+json',
			},
		}),
}
