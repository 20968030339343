import { createAsyncThunk } from '@reduxjs/toolkit'
import api from 'api'
import { ApiMarket } from 'api/market'
import { actions as selectionMarketActions } from 'reducers/market/selection'
import { actions as paginationActions } from 'reducers/pagination'
import { GetParameter, RootState } from 'types/redux'

export const list = {
	get: createAsyncThunk<
		ApiMarket.Search,
		GetParameter<typeof api.market.search> | void,
		{ state: RootState }
	>('market/list/get', async ({ page, size } = {}, { getState, dispatch }) => {
		const { data } = await api.market.search({ page: page ?? getState().pagination.page, size })

		dispatch(
			paginationActions.set({
				page: data.number,
				totalElements: data.totalElements,
			})
		)

		const selection = getState().market.selection.data

		dispatch(
			selectionMarketActions.setAllSelection(
				Object.assign(
					{},
					selection,
					data.content.reduce((acc, { id }) => {
						acc[id] = selection[id] ?? false
						return acc
					}, {} as typeof selection)
				)
			)
		)

		return data
	}),
}
