import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { list } from 'actions/loan/list'
import { LoanDto } from 'converters/loan'
import { commonActions } from 'reducers/common'
import { EntityStatus } from 'types/redux'

export const loanAdapter = createEntityAdapter<LoanDto.BriefDto>()

const initialState = loanAdapter.getInitialState({
	status: 'pending' as EntityStatus,
})

const loan = createSlice({
	name: 'loan/list',
	initialState,
	reducers: {
		reset: commonActions.reset(initialState),
	},
	extraReducers: (builder) => {
		builder
			.addCase(list.get.pending, (state) => {
				if (state.status !== 'pending') state.status = 'pending'
			})
			.addCase(list.get.fulfilled, (state, { payload }) => {
				loanAdapter.setAll(state, payload.content)
				state.status = 'fulfilled'
			})
			.addCase(list.get.rejected, () => ({
				...initialState,
				status: 'rejected',
			}))
	},
})

export const { actions } = loan
export default loan.reducer
