import { DefaultValues } from 'helpers/convertHelper'

import { payment } from '../loan/payments'

import { facility } from './facility'
import { guarantee } from './guarantee'
import { insurance } from './insurance'
import { moneyMovement } from './moneyMovement'
import { participant } from './participant'

export const main = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		number: model.string(['number']),
		product: model.string(['product']),
		loanStartDate: model.string(['loanStartDate']),
		lenderId: model.string(['lenderId']),
		createdAt: model.string(['createdAt']),
		city: model.string(['city']),
		upcomingPaymentDate: model.string(['upcomingPaymentDate']),
		insurance: model.array(['insurance']).map(insurance),
		paymentType: model.const(data.paymentType, {
			ANNUITY: 'ANNUITY',
			PERCENT: 'PERCENT',
			MIXED: 'MIXED',
		} as const),
		paymentTypeRus: model.const(data.paymentType, {
			ANNUITY: 'Аннуитетный',
			PERCENT: 'Процентный',
			MIXED: 'Смешанный',
		} as const),
		mainDebtAmount: model.null<number>(['mainDebtAmount']),
		loanDuration: model.null<number>(['loanDuration']),
		loanAmount: model.null<number>(['loanAmount']),
		annualInterestRate: model.null<number>(['annualInterestRate']),
		upcomingPaymentAmount: model.null<number>(['upcomingPaymentAmount']),
		scheduledPaymentAmount: model.null<number>(['scheduledPaymentAmount']),
		overdueBalanceAmount: model.null<number>(['overdueBalanceAmount']),
		penaltyAmount: model.null<number>(['penaltyAmount']),
		paidMainDebtAmount: model.null<number>(['paidMainDebtAmount']),
		paidPenaltiesAmount: model.null<number>(['paidPenaltiesAmount']),
		paidPercentAmount: model.null<number>(['paidPercentAmount']),
		payments: model
			.array(['payments'])
			.map(payment)
			.sort((a, b) => {
				if (a.to && a.from && b.to && b.from) {
					return a.to - a.from < b.to - b.from ? 1 : -1
				}
				return -1
			}),
		overdueDaysCount: model.null<number>(['overdueDaysCount']),
		ltv: model.null<number>(['ltv']),
		moneyMovements: model.array(['moneyMovements']).map(moneyMovement).reverse(),
		scheduledClosingDate: model.string(['scheduledClosingDate']),
		mortgage: model.null<boolean>(['mortgage']),
		participants: model.array(['participants']).map(participant),
		facilities: model.array(['facilities']).map(facility),
		guarantees: model.array(['guarantees']).map(guarantee),
		depositary: model.string(['depositary']),
		scoreCC: model.null<number>(['scoreCC']),
	}
}
