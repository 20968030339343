import axios from 'axios'
import { sign as signConverter, SignDto } from 'converters/sign'
import { SignatureRequestDto } from 'reducers/sign/types'

import { Options, Pageable } from './types'

const baseUrl = '/v1/documents'

interface GetSignaturesOption extends Options {
	actual: boolean
}

const actions = {
	getSignatures: async (params: GetSignaturesOption) => {
		const result = await axios.get<Pageable<SignDto.Main>>(`${baseUrl}/signature`, {
			params,
		})
		result.data.content = result.data.content.map(signConverter.main)
		return result.data
	},

	sign: (payload: SignatureRequestDto) => axios.post(`${baseUrl}/signature`, payload),
}

export default actions
