import { DefaultValues } from 'helpers/convertHelper'

import { signature } from './signature'

export const document = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		title: model.string(['title']),
		extension: model.string(['extension']),
		size: model.null<number>(['size']),
		createdAt: model.string(['createdAt']),
		url: model.string(['url']),
		categories: {
			id: model.string(['categories', 'id']),
			title: model.string(['categories', 'title']),
		},
		signatures: model.array(['signatures']).map(signature),
	}
}
