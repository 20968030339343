import { DefaultValues } from 'helpers/convertHelper'

export const signature = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		signedAt: model.string(['signedAt']),
		subject: model.string(['subject']),
		type: {
			id: model.string(['type', 'id']),
			title: model.string(['type', 'title']),
		},
	}
}
