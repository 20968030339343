import { DefaultValues } from 'helpers/convertHelper'

export const indicators = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		roomsCount: model.null<number>(['roomsCount']),
		b0022: model.null<boolean>(['b0022']),
		b0023: model.null<boolean>(['b0023']),
		b0025: model.null<boolean>(['b0025']),
		b0026: model.null<boolean>(['b0026']),
		b0027: model.null<boolean>(['b0027']),
		b0030: model.null<boolean>(['b0030']),
		b0033: model.null<boolean>(['b0033']),
		b0034: model.null<boolean>(['b0034']),
		b0035: model.null<boolean>(['b0035']),
		b0036: model.null<boolean>(['b0036']),
		renovation: {
			id: model.string(['renovation', 'id']),
			title: model.string(['renovation', 'title']),
		},
	}
}
