import { createAsyncThunk } from '@reduxjs/toolkit'
import api from 'api'
import { actions } from 'reducers/document'
import { GetParameter } from 'types/redux'

import { notice } from 'components/common/Notice'

export const documentActions = {
	search: createAsyncThunk(
		'documents/search',
		async (payload: GetParameter<typeof api.document.search>) => {
			let page = 0
			const { data } = await api.document.search({ ...payload, page })

			if (data.totalPages < 2) {
				return data.content
			}

			const documents = data.content

			for (let i = 0; i < data.totalPages - 1; i++) {
				page += 1
				const { data } = await api.document.search({ ...payload, page })
				documents.push(...data.content)
			}

			return documents
		}
	),

	download: createAsyncThunk(
		'documents/download',
		async ({ url }: { url: string; title: string }) => {
			window.open(url, '_blank')
		}
	),

	upload: createAsyncThunk(
		'documents/upload',
		async (
			{
				entityId,
				type,
				category,
				files,
			}: { entityId: string; type: string; category: string; files: FileList },
			{ dispatch, rejectWithValue }
		) => {
			try {
				const formData = new FormData()
				const filesArray: File[] = []

				Object.keys(files!).map((key: any) => {
					if (files) {
						formData.append('files', files[key])
						filesArray.push(files[key])
					}
				})

				const { data } = await api.document.upload({
					entityId,
					orderType: type,
					category,
					formData,
					handleProgress: (eventProgress) => {
						dispatch(
							actions.setUploadProgress({
								isProgress: true,
								totalSize: eventProgress.total,
								uploadedSize: eventProgress.loaded,
								files: filesArray.map((file) => ({
									category,
									name: file.name,
									uploaded: eventProgress.loaded,
									size: file.size,
								})),
							})
						)
					},
				})

				return data
			} catch (error: any) {
				notice({ title: 'Ошибка', message: 'Не удалось загрузить документы' })

				return rejectWithValue(error?.response?.data ?? {})
			}
		}
	),
}
