import { fileUtils } from '@creditclubteam/helpers'
import { DefaultValues } from 'helpers/convertHelper'

import { signature } from './signature'

export const main = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		title: model.string(['title']),
		shortTitle: fileUtils.removeFileExtension(model.string(['title'])),
		extension: model.string(['extension']),
		createdAt: model.string(['createdAt']),
		url: model.string(['url']),
		size: model.null<number>(['size']),
		category: {
			id: model.string(['category', 'id']),
			title: model.string(['category', 'title']),
		},
		signatures: model.array(['signatures']).map(signature),
	}
}
