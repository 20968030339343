import { DefaultValues } from 'helpers/convertHelper'

export const dependent = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		birthDate: model.string(['birthDate']),
		status: {
			id: model.string(['status', 'id']),
			title: model.string(['status', 'title']),
		},
	}
}
