import { createAsyncThunk } from '@reduxjs/toolkit'
import api from 'api'
import { GetParameter } from 'types/redux'

export const single = {
	get: createAsyncThunk('loan/single/get', async (params: GetParameter<typeof api.loan.get>) => {
		const { data } = await api.loan.get(params)

		return data
	}),
}
