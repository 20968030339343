import { advertisement } from './advertisement'
import { document } from './document'
import { main } from './main'
import { statement } from './statement'

export const facility = {
	main,
	document,
	statement,
	advertisement,
}

export namespace FacilityDto {
	export type Main = ReturnType<typeof main>
	export type Document = ReturnType<typeof document>
	export type Statement = ReturnType<typeof statement>
	export type Advertisement = ReturnType<typeof advertisement>
}
