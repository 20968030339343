export const ViewDocs = ({ url }: { url: string }) => {
	return (
		<div className='files-viewer-frame'>
			<iframe
				title={url}
				src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`}
			/>
		</div>
	)
}
