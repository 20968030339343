import { document } from './document'
import { main } from './main'

export const entrepreneur = {
	main,
	document,
}

export namespace EntrepreneurDto {
	export type Main = ReturnType<typeof main>
	export type Document = ReturnType<typeof document>
}
