import { main } from './main'
import { signature } from './signature'

export const sign = {
	main,
	signature,
}

export namespace SignDto {
	export type Main = ReturnType<typeof main>
	export type Signature = ReturnType<typeof signature>
}
