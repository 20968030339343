import { defaultToStr, DefaultValues } from 'helpers/convertHelper'
import { utils } from 'helpers/utils'

import { certificate } from './certificate'
import { passport } from './passport'

export const main = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		name: model.string(['name']),
		surname: model.string(['surname']),
		patronymic: model.string(['patronymic']),
		fullName: defaultToStr(utils.getFullName(data)),
		phone: model.string(['phone']),
		email: model.string(['email']),
		snils: model.string(['snils']),
		taxId: model.string(['taxId']),
		gender: model.const(data.gender, { MALE: 'MALE', FEMALE: 'FEMALE' } as const),
		genderRus: model.const(data.gender, { MALE: 'Мужской', FEMALE: 'Женский' } as const),
		createdAt: model.string(['createdAt']),
		certificates: model.array(['certificates']).map(certificate),
		passport: passport(data.passport),
	}
}
