import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { commonActions } from 'reducers/common'
import { RootState } from 'types/redux'

import { AddressBuilder } from '@creditclubteam/helpers'

import { getSuggestAddress } from '../actions/dadata'

interface State {
	query: string
	internal?: any[]
	external?: any[]
}

const initialState: State = {
	query: '',
	internal: undefined,
	external: undefined,
}

const dadata = createSlice({
	name: 'dadata',
	initialState,
	reducers: {
		setInternalData: (state, { payload }: PayloadAction<AddressBuilder[]>) => {
			state.internal = payload
		},

		reset: commonActions.reset(initialState),
	},
	extraReducers: (builder) => {
		builder.addCase(getSuggestAddress.fulfilled, (state, { payload }) => {
			state.external = payload
		})
	},
})

export const selectExternalData = (state: RootState) => state.dadata.external

export const { actions } = dadata
export default dadata.reducer
