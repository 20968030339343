import { DefaultValues } from 'helpers/convertHelper'

import { right } from './right'
import { rightMovementOwners } from './rightMovementOwners'

export const form = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		assignment: model.string(['assignment']),
		address: model.string(['address']),
		floor: model.string(['floor']),
		utilization: model.string(['utilization']),
		floors: model.string(['floors']),
		wallMaterial: model.string(['wallMaterial']),
		registrator: model.string(['registrator']),
		collateralValue: model.null<number>(['collateralValue']),
		cadastralPrice: model.null<number>(['cadastralPrice']),
		area: model.null<number>(['area']),
		builtYear: model.null<number>(['builtYear']),
		lastRepairYear: model.null<number>(['lastRepairYear']),
		egrnRecordMissing: model.null<boolean>(['egrnRecordMissing']),
		type: {
			id: model.string(['type', 'id']),
			title: model.string(['type', 'title']),
		},
		subType: {
			id: model.string(['subType', 'id']),
			title: model.string(['subType', 'title']),
		},
		acquiredInMarriage: {
			id: model.string(['acquiredInMarriage', 'id']),
			title: model.string(['acquiredInMarriage', 'title']),
		},
		maternalCapital: {
			id: model.string(['maternalCapital', 'id']),
			title: model.string(['maternalCapital', 'title']),
		},
		usageType: {
			id: model.string(['usageType', 'id']),
			title: model.string(['usageType', 'title']),
		},

		rights: model.array(['rights']).map(right),
		rightMovementOwners: model.array(['rightMovementOwners']).map(rightMovementOwners),
	}
}
