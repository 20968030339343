import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
	error: false,
	opened: false,
	filesType: '',
	url: '',
}

interface SetInfoAction {
	url: string
	filesType: string
}

const filesViewer = createSlice({
	name: 'filesViewer',
	initialState,
	reducers: {
		setFile(state, { payload }: PayloadAction<SetInfoAction>) {
			const { url, filesType } = payload
			state.opened = true
			state.filesType = filesType
			state.url = url
		},

		reset: (state) => {
			state.error = false
			state.opened = false
			state.filesType = ''
			state.url = ''
		},
	},
})

export default filesViewer.reducer
export const { reset, setFile } = filesViewer.actions
