import { DefaultValues } from 'helpers/convertHelper'

export const bankForm = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		bankName: model.string(['bankName']),
		accountNumber: model.string(['accountNumber']),
		correspondentAccount: model.string(['correspondentAccount']),
		bic: model.string(['bic']),
	}
}
