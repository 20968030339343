import { DefaultValues } from 'helpers/convertHelper'

export const guarantee = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		title: model.string(['title']),
	}
}
