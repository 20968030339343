import { createSlice } from '@reduxjs/toolkit'
import { fetchSignatures, signDocuments } from 'actions/sign'
import { SignDto } from 'converters/sign'
import prop from 'ramda/src/prop'
import symmetricDifference from 'ramda/src/symmetricDifference'

type State = {
	data: Array<SignDto.Main>
	isSignsLoading: boolean
}

const initialState: State = {
	data: [],
	isSignsLoading: false,
}

const sign = createSlice({
	name: 'sign',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchSignatures.pending, (state) => {
			state.isSignsLoading = true
		})

		builder.addCase(fetchSignatures.fulfilled, (state, action) => {
			state.data = action.payload
			state.isSignsLoading = false
		})

		builder.addCase(fetchSignatures.rejected, (state) => {
			state.isSignsLoading = false
		})

		builder.addCase(signDocuments.fulfilled, (state, { payload }) => {
			const signedIds = payload.signatures.map(prop('documentId'))

			state.data = state.data.filter(
				({ documents }) => symmetricDifference(documents.map(prop('id')), signedIds).length !== 0
			)
		})
	},
})

export default sign.reducer
