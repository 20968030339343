import { DefaultValues } from 'helpers/convertHelper'

import { principalActivity } from './principalActivity'

export const form = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		registrationDate: model.string(['registrationDate']),
		registrationNumberPf: model.string(['registrationNumberPf']),
		registrationDatePf: model.string(['registrationDatePf']),
		ogrnip: model.string(['ogrnip']),
		okpo: model.string(['okpo']),
		oktmo: model.string(['oktmo']),
		okato: model.string(['okato']),
		okogu: model.string(['okogu']),
		okopf: model.string(['okopf']),
		okfs: model.string(['okfs']),
		entrepreneurType: model.string(['entrepreneurType']),
		taxAuthorityName: model.string(['taxAuthorityName']),
		activitiesCount: model.null<number>(['activitiesCount']),
		status: model.const(data.status, {
			ACTIVE: 'ACTIVE',
			ELIMINATING: 'ELIMINATING',
			ELIMINATED: 'ELIMINATED',
			REORGANIZATION: 'REORGANIZATION',
			BANKRUPTCY: 'BANKRUPTCY',
			ERRONEOUS_REGISTRATION: 'ERRONEOUS_REGISTRATION',
			UNKNOWN: 'UNKNOWN',
		} as const),
		statusRus: model.const(data.status, {
			ACTIVE: 'Действующее',
			ELIMINATING: 'В стадии ликвидации',
			ELIMINATED: 'Ликвидировано',
			REORGANIZATION: 'В стадии реорганизации',
			BANKRUPTCY: 'В стадии банкротства',
			ERRONEOUS_REGISTRATION: 'Регистрация признана ошибочной',
			UNKNOWN: 'Неизвестно',
		} as const),
		actualAddress: model.null<Record<string, any>>(['actualAddress']),
		legalAddress: model.null<Record<string, any>>(['legalAddress']),
		principalActivity: principalActivity(data.principalActivity),
		complementaryActivities: model.array(['complementaryActivities']).map(principalActivity),
	}
}
