import { Suspense, useEffect, useState } from 'react'
import { RouteObject, UIMatch, useMatch, useMatches, useNavigate } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import { ErrorBoundary } from '@sentry/react'
import { useMetrikaLogger } from 'features/metrika'
import { selectDocumentUploading } from 'reducers/document/selectors'
import { useAppSelector } from 'reducers/hooks'
import { urls } from 'routes/urls'

import { FilesViewer } from 'components/common/FilesViewer'
import { ModalsView } from 'components/common/Modals'
import { checkRoleAccess } from 'components/common/ModuleProvider/ModuleProvider'
import { Navbar } from 'components/common/Navbar'
import { NoticeContainer } from 'components/common/NoticeContainer'
import { PageLoading } from 'components/common/PageLoading'
import { UploadProgressBar } from 'components/common/UploadProgressBar'

import { Error } from './Error'

import 'normalize.css'
import 'react-loading-skeleton/dist/skeleton.css'
import 'rc-pagination/assets/index.css'
import 'react-popper-tooltip/dist/styles.css'
import 'react-toastify/dist/ReactToastify.css'
import 'styles/global.scss'

/**
 * Хук редиректит клиента на страницу заявок, если:
 *
 * 1) На начальном рендере клиент находится на странице index
 * 2) У клиента есть доступ к заявкам
 */
const useRedirect = () => {
	const { keycloak } = useKeycloak()
	const navigate = useNavigate()
	const matchRoute = useMatch(urls.index)
	const matches = useMatches() as UIMatch<unknown, RouteObject['handle']>[]
	const [shouldRedirect] = useState(!!matchRoute)
	const application = matches.find(({ pathname }) => urls.application.list === pathname)

	const hasAccess = application
		? checkRoleAccess(application.handle!.module!.access, 'applications', keycloak.hasResourceRole)
		: true

	useEffect(() => {
		if (shouldRedirect && hasAccess) {
			navigate(urls.application.list)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasAccess])
}

const App = () => {
	const upload = useAppSelector(selectDocumentUploading)

	useMetrikaLogger()
	useRedirect()

	return (
		<ErrorBoundary fallback={<Error />}>
			<div className='App'>
				<NoticeContainer />
				<FilesViewer />
				{upload.isProgress && (
					<UploadProgressBar
						progress={Math.round((upload.uploadedSize * 100) / upload.totalSize)}
					/>
				)}
				<header className='App-header'>
					<Navbar />
				</header>
				<main>
					<Suspense fallback={<PageLoading max />}>
						<Outlet />
					</Suspense>
				</main>
				<ModalsView />
			</div>
		</ErrorBoundary>
	)
}

export default App
