import { DefaultValues } from 'helpers/convertHelper'

export const representatives = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		from: model.string(['from']),
		to: model.string(['to']),
		email: model.string(['email']),
		fullName: model.string(['fullName']),
		phone: model.string(['phone']),
	}
}
