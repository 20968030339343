import { useCallback, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Link, useLocation, useMatches, useNavigate } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import { urls } from 'routes/urls'
import { useTheme } from 'styled-components'

import { Navigation, NavigationProps } from '@creditclubteam/kit/app-components'
import { Image } from 'components/common/Image'
import { MODULE_STATUS_KEY } from 'components/common/ModuleProvider'

import svgLogo from './only-logo.svg'

import styles from './Navbar.module.scss'

const tabs: Array<{ href: string; title: string }> = [
	{
		href: urls.index,
		title: 'Главная',
	},
	{
		href: urls.application.list,
		title: 'Заявки',
	},
	{
		href: urls.loan.list,
		title: 'Кредиты',
	},
	{
		href: urls.signing,
		title: 'Онлайн-подписание',
	},
	{
		href: urls.requests,
		title: 'Запросы',
	},
	{
		href: urls.market.list,
		title: 'Рынок',
	},
]

export const Navbar = () => {
	const theme = useTheme()
	const { pathname } = useLocation()
	const matches = useMatches()
	const navigate = useNavigate()
	const {
		keycloak: { tokenParsed, logout },
	} = useKeycloak()
	const activeHref = tabs.find(
		({ href }) =>
			pathname === href || matches.some(({ pathname: p }) => p !== urls.index && p === href)
	)!.href

	const [activeId, setActiveId] = useState(activeHref)

	useEffect(() => {
		setActiveId(activeHref)
	}, [activeHref])

	const handleLogout = useCallback(() => {
		logout()
		window.localStorage.removeItem(MODULE_STATUS_KEY)
	}, [logout])

	const handleLogoClick = () => {
		navigate(urls.index)
	}

	const navigationProps: NavigationProps = {
		activeId,
		backgroundColor: window.__env__.BUILD_MODE === 'production' ? theme.colors.navigation : '#0178b8',
		logo: tokenParsed?.logo_url ? (
			<Image
				onClick={handleLogoClick}
				height={40}
				width={40}
				src={tokenParsed?.logo_url}
				alt='Логотип кредитора'
			/>
		) : (
			<img onClick={handleLogoClick} width={40} height={40} src={svgLogo} alt='Логотип кредитора' />
		),
		username: tokenParsed?.name ?? 'Имя Оператора',
		onChange: () => null,
		centerItems: tabs.map(({ title, href }) => ({
			id: href,
			node: tokenParsed ? (
				<Link className={styles.link} key={title + href} to={href}>
					{title}
				</Link>
			) : (
				<Skeleton
					key={href}
					height={20}
					width={80}
					borderRadius={4}
					enableAnimation
					baseColor='#d2eef9'
					duration={2}
					highlightColor='#eff1f2'
					containerClassName={styles.load}
				/>
			),
		})),
		onLogout: handleLogout,
	}

	return (
		<div className={styles.navbar}>
			<Navigation {...navigationProps} />
		</div>
	)
}
