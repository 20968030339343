import { EntityCheckResult, entityCheckResult } from 'converters/creditPolicy/entityCheckResult'
import { normalize, schema } from 'normalizr'

import { DefaultValues } from 'helpers/convertHelper'

import { calculation } from './calculation'
import { creditPolicy } from './creditPolicy'

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	const { entities, result } = normalize<EntityCheckResult.Main>(
		model.array(['entityCheckResults']).map(entityCheckResult.main),
		[
			new schema.Entity(
				'entityCheckResults',
				{},
				{
					idAttribute: ({ entity }) => entity.id,
				}
			),
		]
	)

	return {
		calculation: calculation(data.calculation),
		creditPolicy: creditPolicy(data.creditPolicy),
		entityCheckResults: {
			entities: entities.entityCheckResults as Record<string, EntityCheckResult.Main>,
			ids: result as string[],
		},
		decision: model.const(data.decision, {
			APPROVED: 'APPROVED',
			PRE_APPROVED: 'PRE_APPROVED',
			APPROVED_WITH_CONDITIONS: 'APPROVED_WITH_CONDITIONS',
			DENIED: 'DENIED',
		} as const),
		decisionRus: model.const(data.decision, {
			APPROVED: 'Одобрено',
			PRE_APPROVED: 'Предварительно одобрено',
			APPROVED_WITH_CONDITIONS: 'Одобрено под условиями',
			DENIED: 'Отказано',
		} as const),
	}
}
