import { normalize, schema } from 'normalizr'
import uniq from 'ramda/src/uniq'

import { DefaultValues } from 'helpers/convertHelper'

import { checkResult } from './checkResult'
import { entity } from './entity'

type CheckResults = ReturnType<typeof checkResult>[]

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	const { entities, result } = normalize<CheckResults>(
		model.array(['checkResults']).map(checkResult),
		[
			new schema.Entity(
				'group',
				{},
				{
					idAttribute: 'group',
					mergeStrategy: (a, b) => [].concat(a, b),
					processStrategy: (value) => [value],
				}
			),
		]
	)

	return {
		entity: entity(data.entity),
		checkResults: {
			entities: entities.group as Record<string, CheckResults>,
			ids: uniq(result) as string[],
		},
	}
}
