import { Options, Pageable } from 'api/types'
import axios from 'axios'
import { loan as converter, LoanDto } from 'converters/loan'
import assocPath from 'ramda/src/assocPath'

const baseUrl = '/v1/loans'

export namespace ApiLoan {
	export type Search = Pageable<LoanDto.BriefDto>
	export type Get = LoanDto.Main
}

export const loan = {
	search: ({ page, size = 10 }: Options) =>
		axios
			.get<ApiLoan.Search>(baseUrl, { params: { page, size, sort: 'createdAt,desc' } })
			.then((response) =>
				assocPath(['data', 'content'], response.data.content.map(converter.briefDto), response)
			),

	get: ({ id }: { id: string }) =>
		axios
			.get<ApiLoan.Get>(`${baseUrl}/${id}`)
			.then((response) => Object.assign({}, response, { data: converter.main(response.data) })),
}
