import { DefaultValues } from 'helpers/convertHelper'

import { detail } from './detail'

export const upcomingPayment = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		amount: model.null<number>(['amount']),
		balance: model.null<number>(['balance']),
		overdueDays: model.null<number>(['overdueDays']),
		date: model.string(['date']),
		details: model.array(['details']).map(detail),
	}
}
