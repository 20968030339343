import { DefaultValues } from 'helpers/convertHelper'

export const type = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string<'PERSON' | 'FACILITY' | 'ORGANIZATION' | 'ENTREPRENEUR'>(['id']),
		title: model.string(['title']),
	}
}
