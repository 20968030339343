import { ENCUMBRANCE_TYPES } from 'const/encumbranceTypes'

import { DefaultValues } from 'helpers/convertHelper'

export const encumbrance = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		regNumber: model.string(['regNumber']),
		regDate: model.string(['regDate']),
		encumbrancer: model.string(['encumbrancer']),
		encumbrancerType: {
			id: model.string(['encumbrancerType', 'id']),
			title: model.string(['encumbrancerType', 'title']),
		},
		duration: model.string(['duration']),
		docFound: model.string(['docFound']),
		kind: model.string(['kind']),
		kindRus: model.const(data.kind, ENCUMBRANCE_TYPES),
	}
}
