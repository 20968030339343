import { createAsyncThunk } from '@reduxjs/toolkit'
import api from 'api'
import { actions } from 'reducers/organization/single'
import { GetParameter } from 'types/redux'

export const single = {
	get: createAsyncThunk(
		'organization/single/get',
		async (params: GetParameter<typeof api.organization.get>) => {
			const { data } = await api.organization.get(params)

			return data
		}
	),

	documents: createAsyncThunk(
		'organization/single/documents',
		async (params: GetParameter<typeof api.organization.getDocuments>, { dispatch }) => {
			let page = 0
			const { data } = await api.organization.getDocuments({ ...params, page })

			if (data.totalPages < 2) {
				return data.content
			}

			const documents = data.content

			for (let i = 0; i < data.totalPages - 1; i++) {
				page += 1
				const { data } = await api.organization.getDocuments({ ...params, page })
				documents.push(...data.content)
			}

			await dispatch(actions.setDocuments(documents))

			return documents
		}
	),

	...actions,
}
