import { createAsyncThunk } from '@reduxjs/toolkit'
import api from 'api'

export const getSuggestAddress = createAsyncThunk(
	'dadata/getSuggestAddress',
	async (query: string) => {
		const { data } = await api.address.search(query)

		return data
	}
)
