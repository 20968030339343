import { DefaultValues } from 'helpers/convertHelper'

import { courtGroup } from './courtGroup'
import { organization } from './organization'
import { proceedingGroup } from './proceedingGroup'

export const main = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		organization: organization(data.organization),
		proceedingGroup: model.array(['proceedingGroup']).map(proceedingGroup),
		courtGroup: model.array(['courtGroup']).map(courtGroup),
	}
}
