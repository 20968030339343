import { createAsyncThunk } from '@reduxjs/toolkit'
import api from 'api'
import { GetParameter } from 'types/redux'

export const single = {
	get: createAsyncThunk(
		'loanSellApplications/single/get',
		async (payload: GetParameter<typeof api.loanSellApplications.get>) => {
			const { data } = await api.loanSellApplications.get(payload)

			return data
		}
	),

	cancel: createAsyncThunk(
		'loanSellApplications/single/cancel',
		async (payload: GetParameter<typeof api.loanSellApplications.cancel>) => {
			await api.loanSellApplications.cancel(payload)
		}
	),

	documents: createAsyncThunk(
		'loanSellApplications/single/documents',
		async (payload: GetParameter<typeof api.loanSellApplications.cancel>) => {
			const { data } = await api.loanSellApplications.documents(payload)

			return data.content
		}
	),
}
