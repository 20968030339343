import { combineReducers } from '@reduxjs/toolkit'

import application from './application'
import client from './client'
import dadata from './dadata'
import document from './document'
import entrepreneur from './entrepreneur'
import facility from './facility'
import filesViewer from './filesViewer'
import loan from './loan'
import loanSellApplications from './loanSellApplications'
import market from './market'
import modals from './modals'
import organization from './organization'
import pagination from './pagination'
import person from './person'
import sign from './sign'

export const reducers = {
	application,
	market,
	document,
	filesViewer,
	loanSellApplications,
	client,
	person,
	facility,
	loan,
	pagination,
	sign,
	dadata,
	modals,
	entrepreneur,
	organization,
}

const rootReducer = combineReducers(reducers)

export default rootReducer
