import { DefaultValues } from 'helpers/convertHelper'

export const statement = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		link: model.string(['link']),
		createdAt: model.string(['createdAt']),
		docflowState: {
			id: model.string(['docflowState', 'id']),
			title: model.string(['docflowState', 'title']),
		},
		type: {
			id: model.string(['type', 'id']),
			title: model.string(['type', 'title']),
		},
	}
}
