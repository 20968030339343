import { createAsyncThunk } from '@reduxjs/toolkit'
import api from 'api'
import { GetParameter } from 'types/redux'

export const single = {
	get: createAsyncThunk(
		'person/single/get',
		async (params: GetParameter<typeof api.person.get>) => {
			const { data } = await api.person.get(params)

			return data
		}
	),

	documents: createAsyncThunk('person/single/documents', async (params: { id: string }) => {
		let page = 0
		const { data } = await api.person.getDocuments({ ...params, page })

		if (data.totalPages < 2) {
			return data.content
		}

		const documents = data.content

		for (let i = 0; i < data.totalPages - 1; i++) {
			page += 1
			const { data } = await api.person.getDocuments({ ...params, page })
			documents.push(...data.content)
		}

		return documents
	}),

	incomes: createAsyncThunk(
		'person/single/incomes',
		async (params: GetParameter<typeof api.person.getIncomes>) => {
			const { data } = await api.person.getIncomes(params)

			return data
		}
	),
}
