import { DefaultValues } from 'helpers/convertHelper'

export const rightMovementOwners = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		name: model.string(['name']),
		regEndDate: model.string(['regEndDate']),
		regNumber: model.string(['regNumber']),
		regStartDate: model.string(['regStartDate']),
		share: {
			numerator: model.string(['share', 'numerator']),
			denominator: model.string(['share', 'denominator']),
		},
		ownershipType: {
			id: model.string(['ownershipType', 'id']),
			title: model.string(['ownershipType', 'title']),
		},
	}
}
