import { CreditPolicyDto } from 'converters/creditPolicy'
import { normalize, schema } from 'normalizr'
import sort from 'ramda/src/sort'
import uniq from 'ramda/src/uniq'

export interface NormalizedCreditPolicyCalculationData {
	entities: Record<'products' | 'programs', Record<string, string[]>>
	productsIds: string[]
	processedData: CreditPolicyDto.Main[]
}

export const normalizeByProduct = (
	data: CreditPolicyDto.Main[]
): NormalizedCreditPolicyCalculationData => {
	const sorted = sort(
		({ creditPolicy: a }, { creditPolicy: b }) =>
			`${a.product} ${a.program}`.localeCompare(`${b.product} ${b.program}`),
		data
	)

	const dataSchema = new schema.Entity(
		'products',
		[
			new schema.Entity(
				'programs',
				{},
				{
					idAttribute: ({ creditPolicy: { id, program, product } }) =>
						`${id}@${program}@${product}`,
					mergeStrategy: (a, b) => [].concat(a, b),
					processStrategy: ({ creditPolicy }) => [creditPolicy.id],
				}
			),
		],
		{
			idAttribute: ({ creditPolicy: { product } }) => product,
			mergeStrategy: (a, b) => uniq([].concat(a, b)),
			processStrategy: (value) => [value],
		}
	)

	const { entities, result } = normalize(sorted, [dataSchema])

	return {
		entities: entities as NormalizedCreditPolicyCalculationData['entities'],
		productsIds: uniq(result),
		processedData: sorted,
	}
}
