import { DefaultValues } from 'helpers/convertHelper'

export const facility = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		type: model.string(['type']),
		address: model.string(['address']),
		area: model.null<number>(['area']),
		assessmentAmount: model.null<number>(['assessmentAmount']),
	}
}
