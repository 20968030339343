import { DefaultValues } from 'helpers/convertHelper'

export const passport = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		series: model.string(['series']),
		number: model.string(['number']),
		issueDate: model.string(['issueDate']),
		birthDate: model.string(['birthDate']),
		divisionCode: model.string(['divisionCode']),
		birthplace: model.string(['birthplace']),
		department: model.string(['department']),
		registrationAddress: model.null<Record<string, any>>(['registrationAddress']),
	}
}
