import { DefaultValues } from 'helpers/convertHelper'

export const payment = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		from: model.null<number>(['from']),
		to: model.null<number>(['to']),
		annualInterestRate: model.null<number>(['annualInterestRate']),
		paymentAmount: model.null<number>(['paymentAmount']),
	}
}
