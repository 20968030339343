import { DefaultValues } from 'helpers/convertHelper'

export const currentInvestor = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		title: model.string(['title']),
		taxId: model.string(['taxId']),
	}
}
