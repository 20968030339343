import { PropsWithChildren, StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { ErrorBoundary, init, replayIntegration } from '@sentry/react'
import axios from 'axios'
import { Error } from 'client/Error'
import { keycloak } from 'client/keycloak'
import { DataProvider } from 'features/data'
import { MetrikaInitializer } from 'features/metrika'
import { browserRoutes } from 'routes/routes'
import { ThemeProvider } from 'styled-components'
import { preparedTheme } from 'styles/theme'
import { registerSW } from 'virtual:pwa-register'

import { sentryUtils } from '@creditclubteam/helpers'
import { ConfigureAxios } from '@creditclubteam/helpers'
import { PageLoading } from 'components/common/PageLoading'

import { store } from './client'

init({
	dsn: 'https://502513824b5f4f72a97306e7f11b652a@o272008.ingest.sentry.io/6147467',
	enabled: window.__env__.BUILD_MODE !== 'localhost',
	environment: window.__env__.BUILD_MODE,
	debug: window.__env__.BUILD_MODE === 'development',
	ignoreErrors: ['Non-Error exception captured', 'Request aborted'],
	integrations: [replayIntegration()],

	beforeBreadcrumb(breadcrumb) {
		if (sentryUtils.shouldIgnoreBreadcrumb(breadcrumb)) {
			return null
		}

		return breadcrumb
	},

	beforeSend(event, hint) {
		if (
			sentryUtils.shouldSendError(hint?.originalException, {
				ignoreApiErrors: ['invalid_verification_code'],
			}) ||
			event.tags?.action === 'uploading'
		) {
			return event
		}

		return null
	},
})

const configureAxios = new ConfigureAxios()
configureAxios.init({ axios, keycloakInstance: keycloak })

const WithKeycloak = ({ children }: PropsWithChildren<unknown>) => {
	return (
		<ReactKeycloakProvider
			authClient={keycloak}
			LoadingComponent={<PageLoading max />}
			autoRefreshToken={false}
			initOptions={{
				onLoad: 'login-required',
				pkceMethod: 'S256',
				redirectUri: window.location.href,
				checkLoginIframe: false,
			}}
		>
			{children}
		</ReactKeycloakProvider>
	)
}

ReactDOM.render(
	<StrictMode>
		<MetrikaInitializer />
		<ErrorBoundary fallback={Error}>
			<Provider store={store}>
				<DataProvider>
					<ThemeProvider theme={preparedTheme}>
						<WithKeycloak>
							<RouterProvider router={browserRoutes} />
						</WithKeycloak>
					</ThemeProvider>
				</DataProvider>
			</Provider>
		</ErrorBoundary>
	</StrictMode>,
	document.getElementById('root')
)

if (window.__env__.BUILD_MODE !== 'localhost') {
	registerSW({ immediate: true })
}