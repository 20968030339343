import { DefaultValues } from 'helpers/convertHelper'

export const relationType = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string<'PREVIOUS_OWNER' | 'SPOUSE' | 'CONTROLLED_LEGAL_ENTITY'>(['id']),
		title: model.string(['title']),
	}
}
