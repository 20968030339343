import { briefDto } from './briefDto'
import { facility } from './facility'
import { main } from './main'
import { moneyMovement } from './moneyMovement'
import { participant } from './participant'

export const market = {
	briefDto,
	facility,
	participant,
	main,
	moneyMovement,
}

export namespace MarketDto {
	export type BriefDto = ReturnType<typeof briefDto>
	export type Participant = ReturnType<typeof participant>
	export type Main = ReturnType<typeof main>
	export type Facility = ReturnType<typeof facility>
	export type MoneyMovement = ReturnType<typeof moneyMovement>
}
