import { Options, Pageable } from 'api/types'
import axios from 'axios'
import { market as marketConverter, MarketDto } from 'converters/market'
import { assocPath } from 'ramda'

export namespace ApiMarket {
	export type Search = Pageable<MarketDto.BriefDto>
	export type Get = MarketDto.Main
	export type Create = MarketDto.Main
	export type Delete = { id: string }
	export type Apply = void
}

const baseUrls = '/v1/market/loans'

export const market = {
	search: ({ page, size = 10 }: Options) =>
		axios
			.get<ApiMarket.Search>(baseUrls, {
				headers: {
					accept: 'application/page+json',
				},
				params: { page, size, sort: 'loanStartDate,desc' },
			})
			.then((response) =>
				assocPath(
					['data', 'content'],
					response.data.content.map(marketConverter.briefDto),
					response
				)
			),

	get: ({ id }: { id: string }) =>
		axios
			.get<ApiMarket.Get>(`${baseUrls}/${id}`)
			.then((response) =>
				Object.assign({}, response, { data: marketConverter.main(response.data) })
			),

	apply: ({ id }: { id: string }) => axios.post<ApiMarket.Apply>(`${baseUrls}/${id}/apply`),

	create: (data: { loanId: string }) =>
		axios
			.post<ApiMarket.Create>(baseUrls, data)
			.then((response) =>
				Object.assign({}, response, { data: marketConverter.main(response.data) })
			),

	delete: ({ id }: { id: string }) => axios.delete<ApiMarket.Delete>(`${baseUrls}/${id}`),
}
