import { checkResult } from './checkResult'
import { main } from './main'

export const entityCheckResult = {
	main,
	checkResult,
}

export namespace EntityCheckResult {
	export type Main = ReturnType<typeof main>
	export type CheckResult = ReturnType<typeof checkResult>
}
