import { DefaultValues } from 'helpers/convertHelper'
import { utils } from 'helpers/utils'

export const participant = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	const [surname, name, patronymic] = model.string(['title']).split(' ')
	const short = utils.join([surname && `${surname.charAt(0)}.`, name, patronymic], ' ')
	const title = model.string(['title'])

	return {
		title,
		taxId: model.string(['taxId']),
		short: data.taxId ? title : short,
		type: model.const(data.type, {
			PERSON: 'PERSON',
			ENTREPRENEUR: 'ENTREPRENEUR',
			ORGANIZATION: 'ORGANIZATION',
		} as const),
		typeRus: model.const(data.type, {
			PERSON: 'ФЛ',
			ENTREPRENEUR: 'ИП',
			ORGANIZATION: 'ЮЛ',
		} as const),
		roles: model.array<string[]>(['roles']),
	}
}
