import { Options, Pageable } from 'api/types'
import axios from 'axios'
import { lenderRequest as lenderRequestConverter, LenderRequestDto } from 'converters/lenderRequest'

export namespace ApiLenderRequest {
	export type Search = Pageable<LenderRequestDto.Main>
}

const baseUrls = '/v1/requests'

export const lenderRequest = {
	search: async ({
		page,
		size = 10,
		status,
	}: Options & { status: any }): Promise<ApiLenderRequest.Search> => {
		const result = await axios.get<ApiLenderRequest.Search>(baseUrls, {
			params: { page, size, sort: 'createdAt,desc', type: ['PRELIMINARY_SCHEDULE'], status }, // TODO fix sort order
		})
		result.data.content = result.data.content.map(lenderRequestConverter.main)
		return result.data
	},
	respond: ({
		requestId,
		file,
		psk,
		pskAmount,
		averagePayment,
	}: {
		requestId: string
		file: File
		psk: number
		pskAmount: number
		averagePayment: number
	}): Promise<void> => {
		const formData = new FormData()

		formData.append('files', file, 'file2.xlsx')

		const jsonData = { data: { psk, pskAmount, averagePayment } }
		const jsonDataStr = JSON.stringify(jsonData)
		const jsonDataBytes = new TextEncoder().encode(jsonDataStr)
		const jsonDataBlob = new Blob([jsonDataBytes], {
			type: 'application/json;charset=utf-8',
		})
		formData.append('responseData', jsonDataBlob)

		return axios.post(`${baseUrls}/${requestId}/respond`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
	},
}
