import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface State {
	page: number
	totalElements?: number
}

const initialState: State = {
	page: 0,
	totalElements: undefined,
}

const pagination = createSlice({
	name: 'pagination',
	initialState,
	reducers: {
		set(state, { payload }: PayloadAction<Partial<State>>) {
			return { ...state, ...payload }
		},

		reset() {
			return initialState
		},
	},
})

export const { actions } = pagination
export default pagination.reducer
