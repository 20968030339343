import styled from 'styled-components'

import { Button,Text } from '@creditclubteam/kit/ui-components'

const Wrap = styled.div({
	display: 'flex',
	height: '100vh',
	flexDirection: 'column',
	width: '100vw',
	justifyContent: 'center',
	alignItems: 'center',
})

const Content = styled.div({
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'column',
	justifyContent: 'center',
})

export const Error = () => {
	return (
		<Wrap>
			<Content>
				<Text font='large' indent='l'>
					К сожалению, случилась ошибка
				</Text>
				<Button
					variant='blue'
					size='min'
					fullWidth={false}
					type='button'
					onClick={() => window.location.reload()}
				>
					Попробовать еще раз
				</Button>
			</Content>
		</Wrap>
	)
}
