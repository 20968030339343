import { DefaultValues } from 'helpers/convertHelper'

export const advertisement = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']).toString(),
		region: model.string(['region']),
		room: model.string(['room']),
		type: model.string(['type']),
		updateDate: model.string(['updateDate']),
		url: model.string(['url']),
		address: model.string(['address']),
		description: model.string(['description']),
		floor: model.string(['floor']),
		floors: model.string(['floors']),
		wallMaterial: model.string(['wallMaterial']),
		year: model.null<number>(['year']),
		area: model.null<number>(['area']),
		longitude: model.null<number>(['longitude']),
		latitude: model.null<number>(['latitude']),
		price: model.null<number>(['price']),
		pricePerSqm: model.null<number>(['pricePerSqm']),
		photos: model.array(['photos']),
	}
}
