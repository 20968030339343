import { DefaultValues } from 'helpers/convertHelper'

import { calculation } from './calculation'
import { facility } from './facility'
import { guarantee } from './guarantee'
import { insurance } from './insurance'
import { participant } from './participant'

export const main = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		number: model.string(['number']),
		createdAt: model.string(['createdAt']),
		city: model.string(['city']),
		loanPurpose: model.string(['loanPurpose']),
		insurance: model.array(['insurance']).map(insurance),
		facilities: model.array(['facilities']).map(facility),
		participants: model.array(['participants']).map(participant),
		guarantees: model.array(['guarantees']).map(guarantee),
		calculation: calculation(data.calculation),
		ltv: model.null<number>(['ltv']),
		detailedStatus: {
			id: model.string(['detailedStatus', 'id']),
			title: model.string(['detailedStatus', 'title']),
		},
	}
}
