import { DefaultValues } from 'helpers/convertHelper'

import { calculation } from './calculation'
import { facility } from './facility'
import { guarantee } from './guarantee'
import { insurance } from './insurance'
import { moneyMovement } from './moneyMovement'
import { participant } from './participant'
import { upcomingPayment } from './upcomingPayment'

export const main = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		number: model.string(['number']),
		createdAt: model.string(['createdAt']),
		city: model.string(['city']),
		loanPurpose: model.string(['loanPurpose']),
		paidMainDebtAmount: model.null<number>(['paidMainDebtAmount']),
		paidPenaltiesAmount: model.null<number>(['paidPenaltiesAmount']),
		paidPercentAmount: model.null<number>(['paidPercentAmount']),
		mortgage: model.null<boolean>(['mortgage']),
		moneyMovements: model.array(['moneyMovements']).map(moneyMovement).reverse(),
		facilities: model.array(['facilities']).map(facility),
		insurance: model.array(['insurance']).map(insurance),
		participants: model.array(['participants']).map(participant),
		guarantees: model.array(['guarantees']).map(guarantee),
		upcomingPayment: upcomingPayment(data.upcomingPayment),
		calculation: calculation(data.calculation),
		contractDurationMonths: model.null<number>(['contractDurationMonths']),
		ltv: model.null<number>(['ltv']),
		scoreCC: model.null<number>(['scoreCC']),
		depositary: model.string(['depositary']),
	}
}
