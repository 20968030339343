import { defaultToStr, DefaultValues } from 'helpers/convertHelper'
import { utils } from 'helpers/utils'

export const person = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		name: model.string(['name']),
		surname: model.string(['surname']),
		patronymic: model.string(['patronymic']),
		fullName: defaultToStr(utils.getFullName(data)),
		phone: model.string(['phone']),
		email: model.string(['email']),
	}
}
