import { DefaultValues } from 'helpers/convertHelper'

export const ownershipDocument = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		date: model.string(['date']),
		transferAcceptanceCertificateSigningDate: model.string([
			'transferAcceptanceCertificateSigningDate',
		]),
		amount: model.null<number>(['amount']),
		matchWithStatement: model.null<boolean>(['matchWithStatement']),
		b0022: model.null<boolean>(['b0022']),
		b0023: model.null<boolean>(['b0023']),
		b0024: model.null<boolean>(['b0024']),
		b0025: model.null<boolean>(['b0025']),
		b0026: model.null<boolean>(['b0026']),
		b0027: model.null<boolean>(['b0027']),
		b0028: model.null<boolean>(['b0028']),
		b0029: model.null<boolean>(['b0029']),
		b0031: model.null<boolean>(['b0031']),
		b0032: model.null<boolean>(['b0032']),
		b0037: model.null<boolean>(['b0037']),

		type: model.const(data.type, {
			CONTRACT_OF_SALE: 'CONTRACT_OF_SALE',
			DONATION_AGREEMENT: 'DONATION_AGREEMENT',
			BARTER_AGREEMENT: 'BARTER_AGREEMENT',
			PRIVATIZATION_AGREEMENT: 'PRIVATIZATION_AGREEMENT',
			INHERITANCE: 'INHERITANCE',
			EQUITY_AGREEMENT: 'EQUITY_AGREEMENT',
			COURT_DECISION: 'COURT_DECISION',
			ASSIGNMENT_OF_CLAIM: 'ASSIGNMENT_OF_CLAIM',
		} as const),
		typeRus: model.const(data.type, {
			CONTRACT_OF_SALE: 'Договор купли-продажи',
			DONATION_AGREEMENT: 'Договор дарения',
			BARTER_AGREEMENT: 'Договор мены',
			PRIVATIZATION_AGREEMENT: 'Договор приватизации',
			INHERITANCE: 'Наследование',
			EQUITY_AGREEMENT: 'Договор долевого участия',
			COURT_DECISION: 'Решение суда',
			ASSIGNMENT_OF_CLAIM: 'Уступка права требования',
		} as const),
		paymentMethod: model.const(data.paymentMethod, {
			CASH: 'CASH',
			CASHLESS: 'CASHLESS',
			OFFSETTING: 'OFFSETTING',
			EXCHANGE: 'EXCHANGE',
			OTHER: 'OTHER',
		} as const),
		paymentMethodRus: model.const(data.paymentMethod, {
			CASH: 'Передача наличными',
			CASHLESS: 'Безналичный перевод (на р/с)',
			OFFSETTING: 'Путем взаимозачета',
			EXCHANGE: 'Путем мены',
			OTHER: 'Иное',
		} as const),
	}
}
