import { createAsyncThunk } from '@reduxjs/toolkit'
import api from 'api'
import { GetParameter, RootState } from 'types/redux'

import { notice } from 'components/common/Notice'

export const single = {
	get: createAsyncThunk(
		'market/single/get',
		async (params: GetParameter<typeof api.market.get>) => {
			const { data } = await api.market.get(params)

			return data
		}
	),

	apply: createAsyncThunk<
		void,
		GetParameter<typeof api.market.apply> | undefined,
		{ state: RootState }
	>('market/single/apply', async (params, { getState }) => {
		const { data } = await api.market.apply(params ?? { id: getState().market.single.data!.id })

		return data
	}),

	create: createAsyncThunk(
		'market/single/create',
		async (
			params: GetParameter<typeof api.market.create>,
			{ rejectWithValue, fulfillWithValue }
		) => {
			try {
				const { data } = await api.market.create(params)

				notice({
					title: 'Кредит успешно выставлен на продажу',
					message: 'Он доступен для покупки другим участникам платформы в модуле Рынок',
					type: 'success',
				})

				return fulfillWithValue(data)
			} catch (error) {
				return rejectWithValue(error)
			}
		}
	),

	delete: createAsyncThunk(
		'market/single/delete',
		async (
			params: GetParameter<typeof api.market.delete>,
			{ rejectWithValue, fulfillWithValue }
		) => {
			try {
				const { data } = await api.market.delete(params)

				notice({
					title: 'Кредит успешно снят с продажи',
					type: 'success',
				})

				return fulfillWithValue(data)
			} catch (error) {
				notice({
					title: 'Что-то пошло не так.',
					message: 'Свяжитесь с техподдержкой',
				})

				return rejectWithValue(error)
			}
		}
	),
}
