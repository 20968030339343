import { certificate } from './certificate'
import { main } from './main'
import { passport } from './passport'

export const client = {
	main,
	passport,
	certificate,
}

export namespace ClientDto {
	export type Main = ReturnType<typeof main>
	export type Certificate = ReturnType<typeof certificate>
	export type Passport = ReturnType<typeof passport>
}
