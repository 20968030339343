import { createBrowserRouter, RouteObject } from 'react-router-dom'
import App from 'client/App'
import { Error } from 'client/Error'
import {
	Application,
	Entrepreneur,
	Facility,
	Home,
	Loan,
	LoanSellApplications,
	Market,
	OnlineSigning,
	Organization,
	Person,
} from 'components/pages'
import Request from 'components/pages/Request'

import { default as ModuleProvider } from 'components/common/ModuleProvider'

import { urls } from './urls'

export const MODULES = {
	applications: 'Заявка',
	loans: 'Кредиты',
	clients: 'Клиенты',
	signing: 'Подписание',
} as const

export type ModuleAccess = 'write' | 'read'
export type ModuleIds = keyof typeof MODULES
export type ModuleTitles = typeof MODULES[keyof typeof MODULES]
export type BreacrumbsEnum =
	| 'application-list'
	| 'application-single'
	| 'loan-list'
	| 'loan-single'
	| 'market-list'
	| 'market-single'
	| 'loan-sell-application-list'
	| 'loan-sell-application-single'

/**
 * @default isNavbarTab = true
 */
// export type RouteGenerics = MakeGenerics<{
// 	Params: {
// 		id?: string
// 		outId?: string
// 	}
// 	Routehandle: {
// 		title?: string
// 		module?: { id: ModuleIds; title: ModuleTitles; access: ModuleAccess }
// 		isNavbarTab?: boolean
// 		breadcrumb?: BreacrumbsEnum
// 	}
// }>

// type Routes = Route<RouteGenerics>[]

export const getRoutes = (): RouteObject[] => [
	{
		element: <App />,
		ErrorBoundary: Error,
		children: [
			{
				path: urls.index,
				element: <Home />,
				handle: {
					title: 'Главная',
				},
			},
			{
				path: urls.application.list,
				element: <ModuleProvider />,
				handle: {
					title: 'Заявки',
					breadcrumb: 'application-list',
					module: {
						id: 'applications',
						access: 'write',
						title: 'Заявка',
					},
				},
				children: [
					{
						path: urls.application.list,
						element: <Application.List />,
						children: [
							{
								handle: {
									breadcrumb: 'application-single',
								},
								path: urls.application.single(),
								element: <Application.Single />,
								children: [
									{
										path: urls.out.organizations(urls.application.single()),
										element: <Organization.Single />,
									},
									{
										path: urls.out.facilities(urls.application.single()),
										element: <Facility.Single />,
									},
									{
										path: urls.out.entrepreneurs(urls.application.single()),
										element: <Entrepreneur.Single />,
									},
									{
										path: urls.out.persons(urls.application.single()),
										element: <Person.Single />,
									},
								],
							},
						],
					},
				],
			},
			{
				path: urls.loan.list,
				element: <ModuleProvider />,
				handle: {
					title: 'Кредиты',
					breadcrumb: 'loan-list',
					module: {
						id: 'loans',
						access: 'write',
						title: 'Кредиты',
					},
				},
				children: [
					{
						path: urls.loan.list,
						element: <Loan.List />,
						children: [
							{
								handle: {
									breadcrumb: 'loan-single',
								},
								path: urls.loan.single(),
								element: <Loan.Single />,
								children: [
									{
										path: urls.out.organizations(urls.loan.single()),
										element: <Organization.Single />,
									},
									{
										path: urls.out.facilities(urls.loan.single()),
										element: <Facility.Single />,
									},
									{
										path: urls.out.entrepreneurs(urls.loan.single()),
										element: <Entrepreneur.Single />,
									},
									{
										path: urls.out.persons(urls.loan.single()),
										element: <Person.Single />,
									},
								],
							},
						],
					},
				],
			},
			{
				path: urls.signing,
				element: <ModuleProvider />,
				handle: {
					title: 'Онлайн-подписание',
					module: {
						id: 'signing',
						access: 'write',
						title: 'Подписание',
					},
				},
				children: [
					{
						path: urls.signing + urls.signingArchive,
						element: <OnlineSigning actual={false} />,
					},
					{
						path: urls.signing,
						element: <OnlineSigning actual={true} />,
					},
				],
			},
			{
				path: urls.requests,
				handle: {
					title: 'Запросы',
				},
				children: [
					{
						path: urls.requests + urls.requestsArchive,
						element: <Request.List status='ARCHIVE' />,
					},
					{
						path: urls.requests,
						element: <Request.List status='OPEN' />,
					},
				],
			},
			{
				path: urls.market.list,
				handle: {
					title: 'Рынок',
				},
				children: [
					{
						handle: { breadcrumb: 'loan-sell-application-list' },
						path: urls.market.list + urls.loanSellApplications.list,
						element: <LoanSellApplications.List />,
						children: [
							{
								handle: { breadcrumb: 'loan-sell-application-single' },
								path: urls.market.list + urls.loanSellApplications.single(),
								element: <LoanSellApplications.Single />,
							},
						],
					},
					{
						path: urls.market.list,
						element: <Market.List />,
						handle: { breadcrumb: 'market-list' },
						children: [
							{
								path: urls.market.single(),
								handle: { breadcrumb: 'market-single' },
								element: <Market.Single />,
							},
						],
					},
				],
			},
		],
	},
]

export const browserRoutes = createBrowserRouter(getRoutes())
