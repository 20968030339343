import { DefaultValues } from 'helpers/convertHelper'

import { calculation } from './calculation'
import { participant } from './participant'
import { status } from './status'

export const briefDto = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		number: model.string(['number']),
		createdAt: model.string(['createdAt']),
		city: model.string(['city']),
		status: status(data.status),
		scoreCC: model.null<number>(['scoreCC']),
		borrower: model.string(['borrower']),
		calculation: calculation(data.calculation),
		participants: model.array(['participants']).map(participant),
	}
}
