import { DefaultValues } from 'helpers/convertHelper'

import { spouse } from './spouse'

export const marriages = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		marriageDate: model.string(['marriageDate']),
		divorceDate: model.string(['divorceDate']),
		spouse: spouse(data?.spouse),
	}
}
