import { DefaultValues } from 'helpers/convertHelper'

const category = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		title: model.string(['title']),
	}
}

const signature = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		url: model.string(['url']),
		signedAt: model.string(['signedAt']),
	}
}

export const document = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		title: model.string(['title']),
		extension: model.string(['extension']),
		createdAt: model.string(['createdAt']),
		url: model.string(['url']),
		size: model.null<number>(['size']),

		category: category(data.category),
		signatures: model.array(['signatures']).map(signature),
	}
}
