import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'reducers/hooks'
import { actions } from 'reducers/modals/joinUser'

import { Modal } from 'components/common/Modal'

import { UserJoinForm } from './formComponent'

export const ModalUserJoin = () => {
	const dispatch = useAppDispatch()

	const isOpen = useAppSelector((state) => state.modals.joinUser.isOpen)

	const handleClose = useCallback(() => {
		dispatch(actions.closeModal())
	}, [dispatch])

	return (
		<Modal isOpen={isOpen} title='Заявка на подключение' onClose={handleClose}>
			<UserJoinForm />
		</Modal>
	)
}
