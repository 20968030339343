import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'types/redux'

import { documentAdapter } from './index'

export const documentSelectors = documentAdapter.getSelectors((state: RootState) => state.document)

export const selectDocumentsByCategory = createSelector(
	[documentSelectors.selectAll, (_: RootState, categoryId: string) => categoryId],
	(data, categoryId) => data.filter(({ category }) => category.id === categoryId)
)

export const selectHasDocumentsByCategories = createSelector(
	[documentSelectors.selectAll, (_: RootState, categories: string[]) => categories],
	(data, categories) => data.some(({ category }) => categories.includes(category.id))
)

export const selectDocumentStatus = createSelector(
	(state: RootState) => state.document.status,
	(value) => value
)

export const selectDocumentUploading = createSelector(
	(state: RootState) => state.document.upload,
	(value) => value
)
