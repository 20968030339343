import { DefaultValues } from 'helpers/convertHelper'

import { insuranceElement } from './insuranceElement'

export const insurance = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		organization: insuranceElement(data.organization),
		products: model.array(['products']).map(insuranceElement),
	}
}
