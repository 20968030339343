import { DefaultValues } from 'helpers/convertHelper'

export const paymentInterestRateDetails = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		interestRate: {
			unit: model.string(['interestRate', 'unit']),
			value: model.null<number>(['interestRate', 'value']),
		},
		type: model.string(['type']),
	}
}
