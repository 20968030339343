import { useCallback, useState } from 'react'
import { RouteObject, UIMatch, useMatches } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import api from 'api'
import { useFormik } from 'formik'
import { useAppDispatch } from 'reducers/hooks'
import { actions } from 'reducers/modals/joinUser'
import { useLocalStorage } from 'usehooks-ts'

import { join } from '@creditclubteam/kit/helpers'
import { Button, Input, InputProps, useAutocomplete } from '@creditclubteam/kit/ui-components'
import { DEFAULT_MODULE_STATUS, MODULE_STATUS_KEY } from 'components/common/ModuleProvider'
import { defaultToApi } from 'helpers/convertHelper'
import { utils } from 'helpers/utils'

import { validationSchema } from './validation'

import styles from './style.module.scss'

export type DaDataGender = 'MALE' | 'FEMALE' | 'UNKNOWN'

export interface DadataFio {
	surname: string | null
	name: string | null
	patronymic: string | null
	gender: DaDataGender
	qc: '0' | '1'
	source: null
}

export interface DadataResponse {
	suggestions: {
		data: DadataFio
		unrestricted_value: string
		value: string
	}[]
}

const transformValuesToFio = (data: DadataFio | undefined) => {
	const fio = []
	if (data?.surname) {
		fio.push(data.surname)
	}
	if (data?.name) {
		fio.push(data.name)
	}
	if (data?.patronymic) {
		fio.push(data.patronymic)
	}

	return fio.length > 0 ? fio.join(' ') : ''
}

export const UserJoinForm = () => {
	const { keycloak } = useKeycloak()
	const {
		family_name: surname = '',
		given_name: name = '',
		patronymic = '',
		phone = '',
		position = '',
	} = keycloak.tokenParsed ?? {}
	const [, entry] = useMatches() as UIMatch<unknown, RouteObject['handle']>[]
	const [moduleStatus, setModuleStatus] = useLocalStorage(MODULE_STATUS_KEY, DEFAULT_MODULE_STATUS)
	const [query, setQuery] = useState(join([surname, name, patronymic]))
	const [isSuccess, setIsSuccess] = useState(false)
	const dispatch = useAppDispatch()

	const onClose = useCallback(() => {
		dispatch(actions.closeModal())
	}, [dispatch])

	const { values, isSubmitting, errors, setFieldValue, handleSubmit } = useFormik({
		initialValues: {
			name,
			surname,
			patronymic,
			phone,
			position,
		},
		validateOnChange: false,
		validateOnMount: false,
		validateOnBlur: true,
		validationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true)

			const { id, title } = entry?.handle?.module ?? {}

			const payload = Object.entries(values).reduce((acc, [key, value]) => {
				acc[key as keyof typeof values] = defaultToApi(
					key === 'phone' ? utils.unparsePhone(value) : value
				)!
				return acc
			}, {} as typeof values)

			// TODO: Где будем обрабатывать ошибки, или всю отправку проводить через Редакс?
			try {
				await api.modules.requestAction({
					...payload,
					module: title ?? 'Главная',
				})

				if (id) {
					setModuleStatus({ ...moduleStatus, [id]: true })
				}

				setIsSuccess(true)
			} catch {
				setSubmitting(false)
			}
		},
	})

	const autocompleteConfig = useAutocomplete<DadataResponse, DadataFio>({
		request: {
			method: 'POST',
			url: `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio`,
			headers: {
				Authorization: `Token 40255f1ccd5969d50f8fdbfc6e2089c5e684cfa6`,
			},
			data: {
				count: 5,
				query,
			},
		},
		query,
		getOptionValue: ({ label }) => label,
		selected: null,
		getOptionItem: (payload) => ({
			label: transformValuesToFio(payload),
			id: `${payload.name}${payload.surname}${payload.patronymic}`,
			payload,
		}),
		onSelect: ({ payload }) => {
			const fio = transformValuesToFio(payload)
			if (payload?.name) {
				setFieldValue('name', payload.name)
			}
			if (payload?.patronymic) {
				setFieldValue('patronymic', payload.patronymic)
			}
			if (payload?.surname) {
				setFieldValue('surname', payload.surname)
			}
			if (fio) {
				setQuery(fio)
			}
		},
		transformResponse: (response) => response.suggestions.map(({ data }) => data),
	})

	const autocompleteProps: InputProps = {
		label: 'Фамилия, имя, отчество',
		value: query,
		autocompleteConfig,
		indent: 'l',
		error: !!errors['name'],
		errorMessage: errors['name'],
		onChange: ({ target }) => {
			setQuery(target.value)
		},
	}

	return (
		<form autoComplete='off' className={styles.form} onSubmit={handleSubmit}>
			<Input {...autocompleteProps} />
			<Input
				type='text'
				label='Должность'
				name='position'
				indent='l'
				error={!!errors['position']}
				errorMessage={errors['position']}
				value={values.position}
				onChange={(event) => setFieldValue('position', event.target.value)}
			/>
			<Input
				type='text'
				label='Телефон'
				name='phone'
				indent='l'
				mask='+7 999 999-99-99'
				error={!!errors['phone']}
				errorMessage={errors['phone']}
				value={values.phone}
				onChange={(event) => setFieldValue('phone', utils.unparsePhone(event.target.value))}
			/>
			<div className={styles.buttonsBlock}>
				{isSuccess ? (
					<Button disabled={isSubmitting} indent='s' variant='blue' onClick={onClose}>
						Отправлено!
					</Button>
				) : (
					<Button type='submit' disabled={isSubmitting} indent='s' variant='blue'>
						Отправить
					</Button>
				)}
			</div>
		</form>
	)
}
