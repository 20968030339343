import { DefaultValues } from 'helpers/convertHelper'

import { paymentInterestRateDetails } from './paymentInterestRateDetails'

export const payment = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		from: model.null<number>(['from']),
		to: model.null<number>(['to']),
		paymentInterestRate: {
			unit: model.string(['paymentInterestRate', 'unit']),
			value: model.null<number>(['paymentInterestRate', 'value']),
		},
		paymentInterestRateDetails: model
			.array(['paymentInterestRateDetails'])
			.map(paymentInterestRateDetails),
	}
}
