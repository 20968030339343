import { DefaultValues } from 'helpers/convertHelper'

export const checkResult = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		group: model.string(['group']),
		name: model.string(['name']),
		result: model.const(data.result, {
			FOUND: 'FOUND',
			NOT_FOUND: 'NOT_FOUND',
			NO_DATA: 'NO_DATA',
		} as const),
		resultRus: model.const(data.result, {
			FOUND: 'Выявлен',
			NOT_FOUND: 'Не выявлен',
			NO_DATA: 'Нет данных',
		} as const),
	}
}
