import { DefaultValues } from 'helpers/convertHelper'

import { principalActivity } from './principalActivity'

const revenue = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		amountForLastReportingYear: model.null<number>(['amountForLastReportingYear']),
		amountForPenultimateReportingYear: model.null<number>(['amountForPenultimateReportingYear']),
		amountFor2YearsBeforeReportingYear: model.null<number>(['amountFor2YearsBeforeReportingYear']),
	}
}

const assets = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		amountForLastReportingYear: model.null<number>(['amountForLastReportingYear']),
		amountForPenultimateReportingYear: model.null<number>(['amountForPenultimateReportingYear']),
		amountFor2YearsBeforeReportingYear: model.null<number>(['amountFor2YearsBeforeReportingYear']),
	}
}

export const director = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		post: model.string(['post']),
		taxId: model.string(['taxId']),
		date: model.string(['date']),
		fullName: model.string(['fullName']),
		termOfOfficeMonths: model.null<number>(['termOfOfficeMonths']),
	}
}

export const founder = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		fullName: model.string(['fullName']),
		taxId: model.string(['taxId']),
		date: model.string(['date']),
		shareAmount: model.null<number>(['shareAmount']),
		ownershipPercentage: model.null<number>(['ownershipPercentage']),
		type: model.const(data.type, {
			INDIVIDUAL: 'INDIVIDUAL',
			ENTITY: 'ENTITY',
		} as const),
		typeRus: model.const(data.type, {
			INDIVIDUAL: 'ФЛ',
			ENTITY: 'ЮЛ',
		} as const),
	}
}

export const form = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		fullName: model.string(['fullName']),
		shortName: model.string(['shortName']),
		registrationDate: model.string(['registrationDate']),
		kpp: model.string(['kpp']),
		ogrn: model.string(['ogrn']),
		okpo: model.string(['okpo']),
		oktmo: model.string(['oktmo']),
		okato: model.string(['okato']),
		okogu: model.string(['okogu']),
		okopf: model.string(['okopf']),
		okfs: model.string(['okfs']),
		registrationNumberPf: model.string(['registrationNumberPf']),
		registrationDatePf: model.string(['registrationDatePf']),
		registrationNumberFss: model.string(['registrationNumberFss']),
		registrationDateFss: model.string(['registrationDateFss']),
		taxAuthorityName: model.string(['taxAuthorityName']),
		authorizedCapital: model.null<number>(['authorizedCapital']),
		activitiesCount: model.null<number>(['activitiesCount']),
		foundersCount: model.null<number>(['foundersCount']),
		directorAndFounderSame: model.null<boolean>(['directorAndFounderSame']),
		efrsbNegative: model.null<boolean>(['efrsbNegative']),
		status: model.const(data.status, {
			ACTIVE: 'ACTIVE',
			ELIMINATING: 'ELIMINATING',
			ELIMINATED: 'ELIMINATED',
			REORGANIZATION: 'REORGANIZATION',
			BANKRUPTCY: 'BANKRUPTCY',
			ERRONEOUS_REGISTRATION: 'ERRONEOUS_REGISTRATION',
			UNKNOWN: 'UNKNOWN',
		} as const),
		statusRus: model.const(data.status, {
			ACTIVE: 'Действующее',
			ELIMINATING: 'В стадии ликвидации',
			ELIMINATED: 'Ликвидировано',
			REORGANIZATION: 'В стадии реорганизации',
			BANKRUPTCY: 'В стадии банкротства',
			ERRONEOUS_REGISTRATION: 'Регистрация признана ошибочной',
			UNKNOWN: 'Неизвестно',
		} as const),
		actualAddress: model.null<Record<string, any>>(['actualAddress']),
		legalAddress: model.null<Record<string, any>>(['legalAddress']),
		director: director(data.director),
		assets: assets(data.assets),
		revenue: revenue(data.revenue),
		principalActivity: principalActivity(data.principalActivity),
		founders: model.array(['founders']).map(founder),
		complementaryActivities: model.array(['complementaryActivities']).map(principalActivity),
	}
}
