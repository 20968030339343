import { createAsyncThunk } from '@reduxjs/toolkit'
import api from 'api'
import { ApiClient } from 'api/client'
import { actions as paginationActions } from 'reducers/pagination'
import { GetParameter, RootState } from 'types/redux'

export const list = {
	get: createAsyncThunk<
	ApiClient.Search,
		GetParameter<typeof api.client.search> | void,
		{ state: RootState }
	>('client/list/get', async (parameters = {}, { getState, dispatch }) => {
		const { data } = await api.client.search({
			...parameters,
			page: parameters!.page ?? getState().pagination.page,
		})

		if (Number.isFinite(parameters!.page)) {
			dispatch(
				paginationActions.set({
					page: parameters!.page,
				})
			)
		}

		return data
	}),
}
