import axios from 'axios'

const baseUrl = 'https://test.api.credit.club/dadata/address'

const actions = {
	search: (query: string) =>
		axios.get(baseUrl, {
			params: { query },
			headers: {
				authorization: '',
			},
		}),
}

export default actions
