import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'types/redux'

import { marketAdapter } from './list'

export const marketSelectors = marketAdapter.getSelectors((state: RootState) => state.market.list)

export const selectMarketListStatus = createSelector(
	(state: RootState) => state.market.list.status,
	(value) => value
)

export const selectMarketSingleStatus = createSelector(
	(state: RootState) => state.market.single.status,
	(value) => value
)

export const selectMarketSingle = createSelector(
	(state: RootState) => state.market.single.data,
	(value) => value
)

export const selectMarketSelection = createSelector(
	(state: RootState) => state.market.selection.data,
	(value) => value
)

export const selectMarketSelectionIsActive = createSelector(
	(state: RootState) => state.market.selection.isActive,
	(value) => value
)

export const selectMarketGetActiveSelection = createSelector(
	(state: RootState) => state.market.selection.data,
	(value) =>
		Object.entries(value)
			.filter(([, isSelected]) => isSelected)
			.map(([id]) => id)
)

export const selectMarketSelectionLength = createSelector(
	(state: RootState) => state.market.selection,
	(value) => Object.values(value).filter(Boolean).length
)

export const selectMarketSingleIsSelected = createSelector(
	[
		(state: RootState) => state.market.selection.data,
		(state: RootState) => state.market.single.data?.id,
	],
	(selection, id) => !!selection[id!]
)
