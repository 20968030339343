import { DefaultValues } from 'helpers/convertHelper'

export const proceeding = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		category: model.string(['category']),
		number: model.string(['number']),
		startDate: model.string(['startDate']),
		executoryDocument: model.string(['executoryDocument']),
		debtType: model.string(['debtType']),
		debtAmount: model.string(['debtAmount']),
		endDate: model.string(['endDate']),
		endCause: model.string(['endCause']),
	}
}
