import { createAsyncThunk } from '@reduxjs/toolkit'
import api from 'api'
import { ApiApplication } from 'api/application'
import { RootState } from 'types/redux'

export const creditPolicy = {
	get: createAsyncThunk<
		ApiApplication.GetCreditPolicy,
		{ id: string } | void,
		{ state: RootState }
	>('application/creditPolicy', async (params, { getState }) => {
		const { data } = await api.application.getCreditPolicy({
			id: params?.id ?? getState().application.single.data!.id,
		})

		return data
	}),
}
