import { ModuleIds } from 'routes/routes'
export { default } from './ModuleProvider'

export const MODULE_STATUS_KEY = 'module-status'
export const DEFAULT_MODULE_STATUS: Readonly<Record<ModuleIds, boolean>> = {
	applications: false,
	loans: false,
	signing: false,
	clients: false,
}
