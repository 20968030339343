import { DefaultValues } from 'helpers/convertHelper'

import { payment } from './payment'

export const calculation = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		paymentType: model.string(['paymentType']),
		maxLoanAmounts: model.null<number>(['maxLoanAmounts']),
		typeRus: model.const(data.paymentType, {
			ANNUITY: 'Аннуитетный',
			PERCENT: 'Процентный',
			MIXED: 'Смешанный',
		} as const),
		intervals: model.array(['intervals']).map(payment),
	}
}
