import axios from 'axios'
import { document, DocumentDto } from 'converters/document'
import { entrepreneur as converter, EntrepreneurDto } from 'converters/entrepreneur'
import assocPath from 'ramda/src/assocPath'

import { Pageable } from './types'

export namespace ApiEntrepreneur {
	export type Get = EntrepreneurDto.Main
	export type GetDocuments = Pageable<DocumentDto.Main>
}

export const entrepreneur = {
	get: ({ id }: { id: string }) =>
		axios
			.get<ApiEntrepreneur.Get>(`/v1/entrepreneurs/${id}`)
			.then((response) => Object.assign({}, response, { data: converter.main(response.data) })),

	getDocuments: ({ id, page, size = 100 }: { id: string; page?: number; size?: number }) =>
		axios
			.get<ApiEntrepreneur.GetDocuments>(`/v1/entrepreneurs/${id}/documents`, {
				params: { size, page },
			})
			.then((response) =>
				assocPath(['data', 'content'], response.data.content.map(document.main), response)
			),
}
