import axios from 'axios'
import { document, DocumentDto } from 'converters/document'
import { person as converter, PersonDto } from 'converters/person'
import assocPath from 'ramda/src/assocPath'

import { Pageable } from './types'

export namespace ApiPerson {
	export type Get = PersonDto.Main
	export type GetIncomes = PersonDto.Incomes[]
	export type GetDocuments = Pageable<DocumentDto.Main>
}

export const person = {
	get: ({ id }: { id: string }) =>
		axios
			.get<ApiPerson.Get>(`/v1/persons/${id}`)
			.then((response) => Object.assign({}, response, { data: converter.main(response.data) })),

	getIncomes: ({ id }: { id: string }) =>
		axios
			.get<ApiPerson.GetIncomes>(`/v1/persons/${id}/incomes`)
			.then((response) =>
				Object.assign({}, response, { data: response.data.map(converter.incomes) })
			),

	getDocuments: ({ id, page, size = 100 }: { id: string; page: number; size?: number }) =>
		axios
			.get<ApiPerson.GetDocuments>(`/v1/persons/${id}/documents`, {
				params: { size, page },
			})
			.then((response) =>
				assocPath(['data', 'content'], response.data.content.map(document.main), response)
			),
}
