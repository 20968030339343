import { createAsyncThunk } from '@reduxjs/toolkit'
import api from 'api'
import { ApiLoan } from 'api/loan'
import { actions as paginationActions } from 'reducers/pagination'
import { GetParameter, RootState } from 'types/redux'

export const list = {
	get: createAsyncThunk<
		ApiLoan.Search,
		GetParameter<typeof api.loan.search> | void,
		{ state: RootState }
	>('loan/list/get', async (parameters = {}, { getState, dispatch }) => {
		const { data } = await api.loan.search({
			page: getState().pagination.page,
			...parameters,
		})

		dispatch(
			paginationActions.set({
				page: data.number,
				totalElements: data.totalElements,
			})
		)

		return data
	}),
}
