import { DefaultValues } from 'helpers/convertHelper'

import { beneficiary } from './beneficiary'
import { dependent } from './dependent'
import { marriages } from './marriages'
import { workInfo } from './workInfo'

const idAndTitle = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		title: model.string(['title']),
	}
}

export const form = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)


	return {
		dependentsList: model.array(['dependentsList']).map(dependent),
		dependents: model.null<number>(['dependents']),
		publicOfficialRelative: model.null<boolean>(['publicOfficialRelative']),
		officialMonthlyIncome: model.null<number>(['officialMonthlyIncome']),
		representativeExists: model.null<boolean>(['representativeExists']),
		beneficiaryExists: model.null<boolean>(['beneficiaryExists']),
		publicOfficial: model.null<boolean>(['publicOfficial']),
		publicOfficialJobTitle: model.string(['publicOfficialJobTitle']),
		residenceAddressDate: model.string(['residenceAddressDate']),
		publicOfficialRelationKind: model.string(['publicOfficialRelationKind']),
		residenceAddress: model.null<Record<string, any>>(['residenceAddress']),
		marriages: model.array(['marriages']).map(marriages),
		beneficiary: beneficiary(data?.beneficiary),
		natureBusinessRelationships: model.array(['natureBusinessRelationships']).map(idAndTitle),
		familyStatus: idAndTitle(data?.familyStatus),
		businessGoals: idAndTitle(data?.businessGoals),
		ownershipStatus: idAndTitle(data?.ownershipStatus),
		financialActivity: idAndTitle(data?.financialActivity),
		internationalPassportDecision: idAndTitle(data?.internationalPassportDecision),
		financialSituation: idAndTitle(data?.financialSituation),
		businessReputation: idAndTitle(data?.businessReputation),
		workInfo: workInfo(data?.workInfo),
		contactPerson: {
			phoneNumber: {
				countryCode: model.string(['contactPerson', 'phoneNumber', 'countryCode']),
				nationalNumber: model.string(['contactPerson', 'phoneNumber', 'nationalNumber']),
				extension: model.string(['contactPerson', 'phoneNumber', 'extension']),
			},
			fullName: {
				name: model.string(['contactPerson', 'fullName', 'name']),
				surname: model.string(['contactPerson', 'fullName', 'surname']),
				patronymic: model.string(['contactPerson', 'fullName', 'patronymic']),
				fullName: model.string(['contactPerson', 'fullName', 'fullName']),
			},
			relationType: {
				id: model.string(['contactPerson', 'relationType', 'id']),
				title: model.string(['contactPerson', 'relationType', 'title']),
			},
		},
	}
}
