import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { list } from 'actions/loanSellApplications/list'
import { LoanSellApplicationsDto } from 'converters/loanSellApplications'
import { commonActions } from 'reducers/common'
import { EntityStatus } from 'types/redux'

export const loanSellApplicationsAdapter = createEntityAdapter<LoanSellApplicationsDto.Main>()

const initialState = loanSellApplicationsAdapter.getInitialState({
	status: 'pending' as EntityStatus,
})

const loanSellApplications = createSlice({
	name: 'loanSellApplications/list',
	initialState,
	reducers: {
		setAll: loanSellApplicationsAdapter.setAll,
		reset: commonActions.reset(initialState),
	},
	extraReducers: (builder) => {
		builder
			.addCase(list.get.pending, (state) => {
				if (state.status !== 'pending') state.status = 'pending'
			})
			.addCase(list.get.fulfilled, (state, { payload }) => {
				loanSellApplicationsAdapter.setAll(state, payload.content)

				state.status = 'fulfilled'
			})
			.addCase(list.get.rejected, () => ({
				...initialState,
				status: 'rejected',
			}))
	},
})

export const { actions } = loanSellApplications
export default loanSellApplications.reducer
