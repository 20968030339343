const getNestableUrls = <T extends string>(url: T) => ({
	list: `/${url}` as `/${T}`,
	single: (id = ':id') => `/${url}/${id}` as `/${T}/:id`,
})

const getOutUrl =
	<T extends string>(route: T) =>
	<B extends string | null>(base: B, id: string = ':outId') =>
		base ? (`${base}/${route}/${id}` as `${B}/${T}/:outId`) : (`/${route}/${id}` as `/${T}/:outId`)

export const urls = {
	index: '/',
	signing: '/signing',
	signingArchive: '/archive',

	market: getNestableUrls('market-loans'),
	loanSellApplications: getNestableUrls('loan-sell-applications'),
	out: {
		persons: getOutUrl('persons'),
		entrepreneurs: getOutUrl('entrepreneurs'),
		organizations: getOutUrl('organizations'),
		facilities: getOutUrl('facilities'),
	},
	loan: getNestableUrls('loans'),
	application: getNestableUrls('applications'),
	requests: '/requests',
	requestsArchive: '/archive',
} as const
