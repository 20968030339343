import uniqBy from 'ramda/src/uniqBy'

import { DefaultValues } from 'helpers/convertHelper'

import { relation } from './relation'
import { role } from './role'
import { type } from './type'

export const entity = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		name: model.string(['name']),
		roles: uniqBy(({ title }) => title, model.array(['roles']).map(role)),
		relations: model.array(['relations']).map(relation),
		type: type(data.type),
	}
}
