import { DefaultValues } from 'helpers/convertHelper'

export const insuranceElement = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		name: model.string(['name']),
	}
}
