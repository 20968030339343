import { DefaultValues } from 'helpers/convertHelper'

export const detail = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		amount: model.null<number>(['amount']),
		type: model.const(data.type, {
			MAIN_DEBT: 'MAIN_DEBT',
			PERCENT: 'PERCENT',
			MEMBERSHIP_FEE: 'MEMBERSHIP_FEE',
			PENALTIES: 'PENALTIES',
			UNKNOWN: 'UNKNOWN',
			OVERDUE_BALANCE: 'OVERDUE_BALANCE',
		} as const),
		typeRus: model.const(data.type, {
			MAIN_DEBT: 'Основной долг',
			PERCENT: 'Проценты',
			MEMBERSHIP_FEE: 'Членские взносы',
			PENALTIES: 'Пени',
			UNKNOWN: 'Прочее',
			OVERDUE_BALANCE: 'Просроченная задолженность',
		} as const),
	}
}
