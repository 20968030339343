import { DefaultValues } from 'helpers/convertHelper'

const idAndTitle = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		title: model.string(['title']),
	}
}

export const workInfo = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		orgName: model.string(['orgName']),
		orgTaxId: model.string(['orgTaxId']),
		position: model.string(['position']),
		startDate: model.string(['startDate']),
		legalAddress: model.null<Record<string, any>>(['legalAddress']),
		residenceAddress: model.null<Record<string, any>>(['residenceAddress']),
		activityField: idAndTitle(data?.activityField),
		positionLevel: idAndTitle(data?.positionLevel),
		employeeCount: idAndTitle(data?.employeeCount),
		organizationType: idAndTitle(data?.organizationType),
	}
}
