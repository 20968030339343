import { DefaultValues } from 'helpers/convertHelper'

import { relationType } from './relationType'
import { type } from './type'

export const relation = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		name: model.string(['name']),
		type: type(data.type),
		relationTypes: model.array(['relationTypes']).map(relationType),
	}
}
