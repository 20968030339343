import cx from 'clsx'

import styles from './PageLoading.module.scss'

export interface PageLoadingProps {
	min?: boolean
	max?: boolean
	white?: boolean
	fullHeight?: boolean
	fullVh?: boolean
	className?: string
}

export const PageLoading = ({
	min,
	max,
	fullHeight,
	fullVh,
	white,
	className,
}: PageLoadingProps) => (
	<div
		className={cx(styles.loading, {
			[styles.min]: min,
			[styles.max]: max,
			[styles.white]: white,
			[styles.fullHeight]: fullHeight,
			[styles.fullVh]: fullVh,
			[className!]: !!className,
		})}
	>
		<div className={styles.ring}>
			<div />
			<div />
			<div />
			<div />
		</div>
	</div>
)
