import axios from 'axios'
import { document, DocumentDto } from 'converters/document'
import { facility as converter, FacilityDto } from 'converters/facility'
import assocPath from 'ramda/src/assocPath'

import { Pageable } from './types'

export namespace ApiFacility {
	export type Get = FacilityDto.Main
	export type GetDocuments = Pageable<DocumentDto.Main>
	export type GetStatement = FacilityDto.Statement[]
	export type GetAdvertisements = { advertisements: FacilityDto.Advertisement[] }
}

const baseUrl = '/v1/facilities'

export const facility = {
	get: ({ id }: { id: string }) =>
		axios
			.get<ApiFacility.Get>(`${baseUrl}/${id}`)
			.then((response) => Object.assign({}, response, { data: converter.main(response.data) })),

	getDocuments: ({ id, page, size = 100 }: { id: string; page?: number; size?: number }) =>
		axios
			.get<ApiFacility.GetDocuments>(`${baseUrl}/${id}/documents`, { params: { size, page } })
			.then((response) =>
				assocPath(['data', 'content'], response.data.content.map(document.main), response)
			),

	getAdvertisements: ({ id }: { id: string }) =>
		axios.get<ApiFacility.GetAdvertisements>(`${baseUrl}/${id}/assessment`).then((response) =>
			Object.assign({}, response, {
				data: response.data.advertisements.map(converter.advertisement),
			})
		),

	getStatement: ({ id }: { id: string }) =>
		axios.get<ApiFacility.GetStatement>(`${baseUrl}/${id}/statements`).then((response) =>
			Object.assign({}, response, {
				data: response.data.map(converter.statement),
			})
		),
}
