import { defaultToStr, DefaultValues } from 'helpers/convertHelper'
import { utils } from 'helpers/utils'

import { additionalPhoneNumber } from './additionalPhoneNumber'
import { bankForm } from './bankForm'
import { form } from './form'
import { passport } from './passport'
import { representatives } from './representatives'

export const person = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		name: model.string(['name']),
		surname: model.string(['surname']),
		patronymic: model.string(['patronymic']),
		fullName: defaultToStr(utils.getFullName(data)),
		phone: model.string(['phone']),
		email: model.string(['email']),
		snils: model.string(['snils']),
		taxId: model.string(['taxId']),
		nationality: model.string(['nationality']),
		gender: {
			id: model.string(['gender', 'id']),
			title: model.string(['gender', 'title']),
		},
		additionalPhoneNumbers: model.array(['additionalPhoneNumbers']).map(additionalPhoneNumber),
		passport: passport(data?.passport),
		bankForm: bankForm(data?.bankForm),
		form: form(data?.form),
		representatives: model.array(['representatives']).map(representatives),
	}
}
