import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { documentActions } from 'actions/document'
import { entrepreneurActions } from 'actions/entrepreneur'
import { facilityActions } from 'actions/facility'
import { loanSellApplicationsActions } from 'actions/loanSellApplications'
import { organizationActions } from 'actions/organization'
import { personActions } from 'actions/person'
import { DocumentDto } from 'converters/document'
import { commonActions } from 'reducers/common'
import { EntityStatus } from 'types/redux'

export const documentAdapter = createEntityAdapter<DocumentDto.Main>()

interface filesUploadProgress {
	category: string
	name: string
	uploaded: number
	size: number
}

interface filesDownloadProgress {
	id: string
	total: number
	loaded: number
}

const initialState = documentAdapter.getInitialState({
	status: 'pending' as EntityStatus,
	upload: {
		isProgress: false,
		totalSize: 0,
		uploadedSize: 0,
		filesUploadProgress: [] as filesUploadProgress[],
		filesDownloadProgress: [] as filesDownloadProgress[],
	},
})

const document = createSlice({
	name: 'document',
	initialState,
	reducers: {
		setUploadProgress: (state, { payload }) => {
			const { isProgress, totalSize, uploadedSize, files } = payload

			state.upload.isProgress = isProgress
			state.upload.totalSize = totalSize
			state.upload.uploadedSize = uploadedSize
			files.forEach((file: filesUploadProgress) => {
				state.upload.filesUploadProgress = state.upload.filesUploadProgress.filter(
					(storeFile) => storeFile.name !== file.name
				)

				state.upload.filesUploadProgress = [...state.upload.filesUploadProgress, file]
			})

			if (files.length === 0) {
				state.upload.filesUploadProgress = []
			}
		},

		setDownloadProgress: (state, { payload }) => {
			const { id, total, loaded } = payload

			const filesProgress = state.upload.filesDownloadProgress.filter((file) => file.id !== id)
			filesProgress.push({ id, total, loaded })

			const totalSize = filesProgress.reduce((acc, file) => acc + file.total, 0)
			const uploadedSize = filesProgress.reduce((acc, file) => acc + file.loaded, 0)

			if (uploadedSize !== totalSize) {
				state.upload.isProgress = true
				state.upload.totalSize = totalSize
				state.upload.uploadedSize = uploadedSize
			} else {
				state.upload = initialState.upload
			}
		},

		reset: commonActions.reset(initialState),
	},
	extraReducers: (builder) => {
		builder
			.addCase(documentActions.search.pending, (state) => {
				if (state.status !== 'pending') state.status = 'pending'
			})
			.addCase(documentActions.search.fulfilled, (state, { payload }) => {
				documentAdapter.upsertMany(state, payload)
				state.status = 'fulfilled'
			})
			.addCase(documentActions.search.rejected, (state) => {
				state.status = 'rejected'
			})

		builder
			.addCase(loanSellApplicationsActions.single.documents.pending, (state) => {
				if (state.status !== 'pending') state.status = 'pending'
			})
			.addCase(loanSellApplicationsActions.single.documents.fulfilled, (state, { payload }) => {
				documentAdapter.upsertMany(state, payload)
				state.status = 'fulfilled'
			})
			.addCase(loanSellApplicationsActions.single.documents.rejected, (state) => {
				state.status = 'rejected'
			})

		builder
			.addCase(documentActions.upload.fulfilled, (state, { payload }) => {
				documentAdapter.upsertMany(state, payload)
				state.upload = initialState.upload
			})
			.addCase(documentActions.upload.rejected, (state) => {
				state.upload = initialState.upload
			})

		builder
			.addCase(personActions.single.documents.pending, (state) => {
				if (state.status !== 'pending') state.status = 'pending'
			})
			.addCase(personActions.single.documents.fulfilled, (state, { payload }) => {
				documentAdapter.upsertMany(state, payload)
				state.status = 'fulfilled'
			})
			.addCase(personActions.single.documents.rejected, (state) => {
				state.status = 'rejected'
			})

		builder
			.addCase(entrepreneurActions.single.documents.pending, (state) => {
				if (state.status !== 'pending') state.status = 'pending'
			})
			.addCase(entrepreneurActions.single.documents.fulfilled, (state, { payload }) => {
				documentAdapter.upsertMany(state, payload)
				state.status = 'fulfilled'
			})
			.addCase(entrepreneurActions.single.documents.rejected, (state) => {
				state.status = 'rejected'
			})

		builder
			.addCase(organizationActions.single.documents.pending, (state) => {
				if (state.status !== 'pending') state.status = 'pending'
			})
			.addCase(organizationActions.single.documents.fulfilled, (state, { payload }) => {
				documentAdapter.upsertMany(state, payload)
				state.status = 'fulfilled'
			})
			.addCase(organizationActions.single.documents.rejected, (state) => {
				state.status = 'rejected'
			})

		builder
			.addCase(facilityActions.single.documents.pending, (state) => {
				if (state.status !== 'pending') state.status = 'pending'
			})
			.addCase(facilityActions.single.documents.fulfilled, (state, { payload }) => {
				documentAdapter.upsertMany(state, payload)
				state.status = 'fulfilled'
			})
			.addCase(facilityActions.single.documents.rejected, (state) => {
				state.status = 'rejected'
			})
	},
})

export const { actions } = document
export default document.reducer
