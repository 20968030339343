import * as React from 'react'

import { lazyLoadHandler } from 'helpers/lazyLoadHandler'

const List = React.lazy(() => lazyLoadHandler(() => import('./List')))
// const Single = React.lazy(() => lazyLoadHandler(() => import('./Single')))

export default {
	// Single,
	List,
}
