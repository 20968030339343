import { document } from './document'
import { director, founder } from './form'
import { main } from './main'

export const organization = {
	main,
	document,
}

export namespace OrganizationDto {
	export type Main = ReturnType<typeof main>
	export type Founder = ReturnType<typeof founder>
	export type Director = ReturnType<typeof director>
	export type Document = ReturnType<typeof document>
}
