import { DefaultValues } from 'helpers/convertHelper'

export const passport = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		series: model.string(['series']),
		number: model.string(['number']),
		issueDate: model.string(['issueDate']),
		birthDate: model.string(['birthDate']),
		divisionCode: model.string(['divisionCode']),
		birthplace: model.string(['birthplace']),
		department: model.string(['department']),
		registrationDate: model.string(['registrationDate']),
		documentType: model.const(data?.documentType, {
			RUSSIAN_PASSPORT: 'RUSSIAN_PASSPORT',
			FOREIGN_PASSPORT: 'FOREIGN_PASSPORT',
			RESIDENCE: 'RESIDENCE',
			MIGRATION_CARD: 'MIGRATION_CARD',
			OTHER: 'OTHER',
		} as const),
		documentTypeRus: model.const(data?.documentType, {
			RUSSIAN_PASSPORT: 'Российский паспорт',
			FOREIGN_PASSPORT: 'Иностранный паспорт',
			RESIDENCE: 'Вид на жительство',
			MIGRATION_CARD: 'Миграционная карта',
			OTHER: 'Другое',
		} as const),
		married: model.null<boolean>(['married']),
		registration: model.null<Record<string, any>>(['registration']),
		ownershipStatus: {
			id: model.string(['ownershipStatus', 'id:']),
			title: model.string(['ownershipStatus', 'title']),
		},
	}
}
