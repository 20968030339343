import { createSlice } from '@reduxjs/toolkit'
import { single } from 'actions/market/single'
import { MarketDto } from 'converters/market'
import { commonActions } from 'reducers/common'
import { EntityStatus } from 'types/redux'

const initialState = {
	data: null as null | MarketDto.Main,
	status: 'pending' as EntityStatus,
}

const market = createSlice({
	name: 'market/single',
	initialState,
	reducers: {
		reset: commonActions.reset(initialState),
	},
	extraReducers: (builder) => {
		builder
			.addCase(single.get.pending, (state) => {
				if (state.status !== 'pending') state.status = 'pending'
			})
			.addCase(single.get.fulfilled, (state, { payload }) => {
				state.data = payload
				state.status = 'fulfilled'
			})
			.addCase(single.get.rejected, () => ({
				...initialState,
				status: 'rejected',
			}))
	},
})

export const { actions } = market
export default market.reducer
