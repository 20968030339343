import { Suspense, useCallback } from 'react'
import {
	Outlet,
	RouteObject,
	UIMatch,
	useMatches,
} from 'react-router-dom'
import CheckAll from '@iconify/icons-zmdi/check-all'
import { Icon } from '@iconify/react/dist/offline'
import { useKeycloak } from '@react-keycloak/web'
import { useAppDispatch } from 'reducers/hooks'
import { actions } from 'reducers/modals/joinUser'
import { ModuleAccess, ModuleIds } from 'routes/routes'
import { useLocalStorage } from 'usehooks-ts'

import { Button } from 'components/common/Button'
import { FadeInComponent } from 'components/common/FadeIn'
import { PageLoading } from 'components/common/PageLoading'
import { Text } from 'components/common/Text'

import svgApplication from './svg/application.svg'
import svgClient from './svg/client.svg'
import svgLoan from './svg/loan.svg'
import svgSign from './svg/sign.svg'
import { DEFAULT_MODULE_STATUS, MODULE_STATUS_KEY } from './index'

import styles from './ModuleProvider.module.scss'

export const checkRoleAccess = (
	type: ModuleAccess,
	moduleId: ModuleIds,
	cb: Keycloak.KeycloakInstance['hasResourceRole']
) => cb(`${type}-${moduleId}`, 'lender')

const map: Record<ModuleIds, string> = {
	applications: svgApplication,
	loans: svgLoan,
	signing: svgSign,
	clients: svgClient,
}

const ModuleProvider = () => {
	const [moduleStatus] = useLocalStorage<Record<ModuleIds, boolean>>(
		MODULE_STATUS_KEY,
		DEFAULT_MODULE_STATUS
	)
	const { keycloak } = useKeycloak()

	const [, { handle }] = useMatches() as UIMatch<unknown, RouteObject['handle']>[]
	const dispatch = useAppDispatch()

	const pageModule = handle!.module!
	const isRequested = moduleStatus[pageModule.id]
	const hasAccess = checkRoleAccess(pageModule.access, pageModule.id, keycloak.hasResourceRole)

	const handleOpen = useCallback(() => {
		dispatch(actions.openModal())
	}, [dispatch])

	if (hasAccess) {
		return (
			<Suspense fallback={<PageLoading max />}>
				<Outlet />
			</Suspense>
		)
	}

	const svg = map[pageModule.id]

	return (
		<FadeInComponent>
			<div className={styles.wrap}>
				{Image && (
					<div className={styles.img}>
						<img src={svg} />
					</div>
				)}
				{isRequested ? (
					<Text type='h3' indent='l'>
						Заявка отправлена <Icon icon={CheckAll} color='02b710' />
					</Text>
				) : (
					<>
						<Text type='h3' indent='l'>
							Доступно для подключения
						</Text>
						<Text className={styles.text}>
							Оставьте заявку, чтобы мы рассказали о модуле в подробностях и запустили его на
							стороне вашей финансовой компании
						</Text>
						<Button theme='blue' onClick={handleOpen}>
							Подключиться
						</Button>
					</>
				)}
			</div>
		</FadeInComponent>
	)
}

export default ModuleProvider
