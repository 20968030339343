import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { single } from 'actions/facility/single'
import { FacilityDto } from 'converters/facility'
import { commonActions } from 'reducers/common'
import { EntityStatus } from 'types/redux'

export const facilityAdvertisementsAdapter = createEntityAdapter<FacilityDto.Advertisement>()

const initialState = facilityAdvertisementsAdapter.getInitialState({
	status: 'pending' as EntityStatus,
})

const advertisements = createSlice({
	name: 'facility/advertisements',
	initialState,
	reducers: {
		reset: commonActions.reset(initialState),
	},
	extraReducers: (builder) => {
		builder
			.addCase(single.advertisements.pending, (state) => {
				if (state.status !== 'pending') state.status = 'pending'
			})
			.addCase(single.advertisements.fulfilled, (state, action) => {
				facilityAdvertisementsAdapter.upsertMany(state, action.payload)
				state.status = 'fulfilled'
			})
			.addCase(single.advertisements.rejected, () => ({
				...initialState,
				status: 'rejected',
			}))
	},
})

export const { actions } = advertisements
export default advertisements.reducer
