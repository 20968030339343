import { createSlice } from '@reduxjs/toolkit'
import { single } from 'actions/loan/single'
import { LoanDto } from 'converters/loan'
import { commonActions } from 'reducers/common'
import { EntityStatus } from 'types/redux'
import { Maybe } from 'types/utils'

interface State {
	data: Maybe<LoanDto.Main>
	status: EntityStatus
}

const initialState: State = {
	data: null,
	status: 'pending',
}

const loan = createSlice({
	name: 'loan/single',
	initialState,
	reducers: {
		reset: commonActions.reset(initialState),
	},
	extraReducers: (builder) => {
		builder
			.addCase(single.get.pending, (state) => {
				if (state.status !== 'pending') state.status = 'pending'
			})
			.addCase(single.get.fulfilled, (state, { payload }) => {
				state.data = payload
				state.status = 'fulfilled'
			})
			.addCase(single.get.rejected, () => ({
				...initialState,
				status: 'rejected',
			}))
	},
})

export const { actions } = loan
export default loan.reducer
