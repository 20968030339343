import { reset } from 'reducers/filesViewer'
import { useAppDispatch, useAppSelector } from 'reducers/hooks'

import { ViewDocs } from './ViewDocs'
import { ViewImages } from './ViewImages'
import { ViewPDF } from './ViewPDF'

import styles from './FilesViewer.module.scss'

const getViewer = (type: string, url: string) => {
	switch (type) {
		case 'pdf':
			return <ViewPDF url={url} />
		case 'docs':
			return <ViewDocs url={url} />
		case 'jpg':
			return <ViewImages url={url} />
		case 'jpeg':
			return <ViewImages url={url} />
		case 'png':
			return <ViewImages url={url} />
		case 'tiff':
			return <ViewImages url={url} />
		default:
			return <div className='files-viewer-frame'>Неизвестный формат файла</div>
	}
}

export const FilesViewer = () => {
	const rootDiv = document.getElementsByTagName('body')[0]
	const data = useAppSelector((state) => state.filesViewer)
	const dispatch = useAppDispatch()

	const handleClose = () => {
		dispatch(reset())
	}

	if (data.opened) {
		rootDiv.style.overflow = 'hidden'

		return (
			<div className={styles.overlay}>
				<div className={styles.container}>{getViewer(data.filesType, data.url)}</div>
				<div className={styles.close} onClick={handleClose}>
					<i className='zmdi zmdi-close' />
				</div>
			</div>
		)
	}

	rootDiv.removeAttribute('style')

	return null
}
