export const PARTICIPANT_GENDER = {
	MALE: 'Мужской',
	FEMALE: 'Женский',
} as const

export const PARTICIPANT_ROLES_RUS = {
	APPLICANT: 'заявитель',
	BORROWER: 'заёмщик',
	OWNER: 'залогодатель',
	WARRANTOR: 'поручитель',
	INVESTOR: 'кредитор',
}

export const PARTICIPANT_TYPE = {
	PERSON: 'PERSON',
	ORGANIZATION: 'ORGANIZATION',
	ENTREPRENEUR: 'ENTREPRENEUR',
}
