import { briefDto } from './briefDto'
import { calculation } from './calculation'
import { facility } from './facility'
import { main } from './main'
import { participant } from './participant'

export const application = {
	briefDto,
	main,
	facility,
	calculation,
	participant,
}

export namespace ApplicationDto {
	export type BriefDto = ReturnType<typeof briefDto>
	export type Calculation = ReturnType<typeof calculation>
	export type Participant = ReturnType<typeof participant>
	export type Facility = ReturnType<typeof facility>
	export type Main = ReturnType<typeof main>
}
