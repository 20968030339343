export const ENCUMBRANCE_TYPES = {
	'022001000000': 'Сервитут',
	'022001001000': 'Публичный сервитут',
	'022001002000': 'Частный сервитут',
	'022002000000': 'Арест',
	'022003000000': 'Запрещение',
	'022004000000':
		'Ограничения прав на земельный участок, предусмотренные статьями 56, 56.1 Земельного кодекса Российской Федерации',
	'022004001000':
		'Ограничения прав на земельный участок, предусмотренные статьей 56 Земельного кодекса Российской Федерации',
	'022004002000':
		'Ограничения прав на земельный участок, предусмотренные статьей 56.1 Земельного кодекса Российской Федерации',
	'022005000000': 'Решение об изъятии земельного участка, жилого помещения',
	'022006000000': 'Аренда (в том числе, субаренда)',
	'022007000000': 'Ипотека',
	'022008000000': 'Ипотека в силу закона',
	'022009000000': 'Безвозмездное (срочное) пользование земельным/лесным участком',
	'022010000000': 'Доверительное управление',
	'022011000000': 'Рента',
	'022012000000': 'Запрет на совершение действий в сфере ГКУ в отношении ОН',
	'022099000000': 'Иные ограничения (обременения) прав',
} as const
