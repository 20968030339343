import { DefaultValues } from 'helpers/convertHelper'

export const loan = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		number: model.string(['number']),
		createdAt: model.string(['createdAt']),
		loanAmount: model.null<number>(['loanAmount']),
		loanDurationMonths: model.null<number>(['loanDurationMonths']),
		annualInterestRate: model.null<number>(['annualInterestRate']),
	}
}
