import { Pageable } from 'api/types'
import axios from 'axios'
import { document as converter, DocumentDto } from 'converters/document'
import assocPath from 'ramda/src/assocPath'

const baseUrl = '/v1/documents'

export namespace ApiDocument {
	export type Search = Pageable<DocumentDto.Main>
	export type Upload = DocumentDto.Main[]
}

export const document = {
	search: ({ entityId, type, page = 0 }: { entityId: string; type: string, page?: number }) =>
		axios
			.get<ApiDocument.Search>(`${baseUrl}/${entityId}`, {
				params: { type, size: 100, page },
			})
			.then((response) =>
				assocPath(['data', 'content'], response.data.content.map(converter.main), response)
			),

	upload: ({
		entityId,
		orderType,
		category,
		formData,
		handleProgress,
	}: {
		entityId: string
		orderType: string
		category: string
		formData: FormData
		handleProgress: (event: any) => void
	}) =>
		axios.post<ApiDocument.Upload>(`/v1/documents/upload`, formData, {
			params: {
				entityId,
				orderType,
				category,
			},
			onUploadProgress: handleProgress,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}),
}
