import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { commonActions } from 'reducers/common'

const initialState = {
	data: {} as Record<string, boolean>,
	isActive: false,
}

const market = createSlice({
	name: 'market/selection',
	initialState,
	reducers: {
		setSelection: (state, { payload }: PayloadAction<{ id: string; checked: boolean }>) => {
			state.data[payload.id] = payload.checked
		},

		setAllSelection: (state, { payload }: PayloadAction<typeof initialState['data']>) => {
			state.data = payload
		},

		setIsActive: (state, { payload }: PayloadAction<boolean>) => {
			state.isActive = payload
		},

		reset: commonActions.reset(initialState),
	},
})

export const { actions } = market
export default market.reducer
