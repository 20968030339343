import { ReactNode } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

interface DataProviderProps {
	children: ReactNode
}

// Deal with retry
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 1000 * 20, // globally default to 20 seconds
			refetchOnWindowFocus: false,
		},
	},
})

export const DataProvider = ({ children }: DataProviderProps): JSX.Element => (
	<QueryClientProvider client={queryClient}>
		{children}
		<ReactQueryDevtools />
	</QueryClientProvider>
)
