import { DefaultValues } from 'helpers/convertHelper'

import { calculation } from './calculation'
import { participant } from './participant'

export const briefDto = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		number: model.string(['number']),
		createdAt: model.string(['createdAt']),
		city: model.string(['city']),
		calculation: calculation(data.calculation),
		participants: model.array(['participants']).map(participant),
		detailedStatus: {
			id: model.string(['detailedStatus', 'id']),
			title: model.string(['detailedStatus', 'title']),
		},
		borrower: model.string(['borrower']),
	}
}
