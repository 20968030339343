import { combineReducers } from '@reduxjs/toolkit'

import creditPolicy from './creditPolicy'
import list from './list'
import single from './single'

export default combineReducers({
	list,
	single,
	creditPolicy,
})
